import { createContext } from 'react';

import { getDefaultConfiguration } from '../../../../Pages/Settings/SettingsContent/Components/BusinessInfo/businessInfoConfiguration.helper';
import { ListSearchDropdownContextValues } from './types';

const defaultValues = getDefaultConfiguration();

export const ListSearchDropdownContext =
  createContext<ListSearchDropdownContextValues>({
    configuration: defaultValues,
    isLoading: false,
    availableFilters: undefined,
    closeSearchResults: () => {},
  });
