import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useClickOutside } from 'UI/Components/AutoComplete/hooks/useClickOutside';
import useIsMobile from 'Utils/hooks/useIsMobile';
import PendingContent from 'UI/Components/PendingContent/PendingContent';
import { ListSearchDropdownContext } from './ListSearchDropdownContext';
import styles from './style.module.css';
import { useSearchDropdownListItemRefs } from './hooks/useSearchDropdownListItemRefs';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export type ListSearchDropdownResultsItem = {
  id: string;
  label: string;
  type?: string;
};

export type ListSearchDropdownResultsProps = {
  items?: ListSearchDropdownResultsItem[];
  maxHeight?: number;
  onItemClick?: (item: ListSearchDropdownResultsItem) => void;
  toggle: (state: boolean) => void;
  isOpen: boolean;
  resultsClassname?: string;
  focusFirstItem?: boolean;
  setActiveDescendantId: (id: string) => void;
  listRef: React.RefObject<HTMLDivElement>;
};

type ListSearchDropdownResultItemProps = {
  item: ListSearchDropdownResultsItem;
  type?: string;
  isActive: boolean;
  itemIndex: number;
  className?: string;
  resultsRef?: React.Ref<HTMLElement>;
  setActiveIdx: (itemIdx: number) => void;
  onItemClick: (item: ListSearchDropdownResultsItem) => void;
};

const ListSearchDropdownResultsItem: FunctionComponent<
  ListSearchDropdownResultItemProps
> = ({
  item,
  isActive,
  itemIndex,
  className,
  resultsRef,
  setActiveIdx,
  onItemClick,
}) => {
  return (
    <span
      onClick={() => onItemClick(item)}
      className={cx(styles.ListSearchDropdownResultsItem, className, {
        [styles.ListSearchDropdownResultsItemActive]: isActive,
      })}
      data-testid="listSearchDropdown-results-item"
      onMouseEnter={() => setActiveIdx(itemIndex)}
      onMouseLeave={() => setActiveIdx(0)}
      role="option"
      tabIndex={isActive ? 0 : -1}
      aria-selected={!!isActive}
      ref={resultsRef}
      id={isActive ? 'selectedItem' : ''}
      onKeyDown={(e) => onEnterKeydown(e, () => onItemClick(item))}
    >
      <div
        className={styles.ListSearchDropdownResultsItemLabel}
        aria-live="polite"
        role="status"
      >
        {item.label}
      </div>
    </span>
  );
};

const ListSearchDropdownResults: FunctionComponent<
  ListSearchDropdownResultsProps
> = ({
  items,
  maxHeight,
  onItemClick,
  toggle,
  isOpen,
  resultsClassname,
  focusFirstItem,
  setActiveDescendantId,
  listRef,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { configuration, isLoading, closeSearchResults, availableFilters } =
    useContext(ListSearchDropdownContext);
  const isMobile = useIsMobile();
  const { ref } = useClickOutside({
    toggler: () => {
      toggle(false);
    },
    state: isOpen,
  });

  useEffect(() => {
    if (focusFirstItem && items?.length) {
      setActiveIndex(0);
    }
  }, [focusFirstItem, items]);

  const shouldShowScrollbar = useMemo(() => {
    return items?.length && items.length > 3;
  }, [items]);

  const onClick = (item: ListSearchDropdownResultsItem, index: number) => {
    if (configuration.closeOnSelectItem) {
      closeSearchResults();
    }
    onItemClick?.(item);
    setActiveIndex(index);
  };

  const shouldShowNoResultsText = !items?.length && !isLoading;
  const hasResults = Boolean(items?.length);

  const getFilteredResults = (
    itemType: string,
    items?: ListSearchDropdownResultsItem[]
  ) => {
    if (!items) {
      return [];
    }
    return items.filter((item) => item.type === itemType);
  };

  const itemsListForRefs = items || [];
  const itemsRefs = useSearchDropdownListItemRefs(itemsListForRefs);
  useCallback(
    (index: number) => (el: HTMLDivElement | null) => {
      itemsRefs.current[index] = el;
    },
    [itemsRefs]
  );

  useEffect(() => {
    if (
      activeIndex !== null &&
      activeIndex >= 0 &&
      activeIndex < itemsListForRefs.length
    ) {
      itemsRefs.current[activeIndex]?.focus();
    }
  }, [activeIndex, itemsListForRefs]);

  useEffect(() => {
    if (!items || !activeIndex) return;

    setActiveDescendantId(items[activeIndex].id);
  }, [activeIndex]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!items) return;
    switch (event.key) {
      case 'ArrowDown':
        setActiveIndex((prevIndex) => {
          if (prevIndex === null) return 0;
          const nextIndex =
            activeIndex !== items.length - 1 ? prevIndex + 1 : 0;
          return nextIndex;
        });
        event.preventDefault();
        break;
      case 'ArrowUp':
        setActiveIndex((prevIndex) => {
          if (prevIndex === null) return items.length - 1;
          const nextIndex =
            activeIndex !== 0 ? prevIndex - 1 : items.length - 1;
          return nextIndex;
        });
        event.preventDefault();
        break;
      case 'Enter' || ' ' || 'Spacebar':
        if (activeIndex !== null) {
          onItemClick?.(items[activeIndex]);
        }
        break;
      case 'Tab':
        if (event.shiftKey) {
          setActiveIndex(null);
        }
        break;
      default:
        break;
    }
  };

  const renderResults = () => {
    if (!hasResults) {
      return null;
    }
    if (availableFilters?.length && !configuration.displayFilters) {
      return (
        <div
          onKeyDown={handleKeyDown}
          tabIndex={1}
          role="listbox"
          ref={listRef}
        >
          {availableFilters?.map((filter) => {
            const filteredResults = getFilteredResults(filter.name, items);
            return (
              <React.Fragment key={filter.name}>
                {filteredResults.map((item, idx) => {
                  return (
                    <ListSearchDropdownResultsItem
                      item={item}
                      onItemClick={() => onClick(item, idx)}
                      key={`${item.id}-${idx}`}
                      isActive={!!activeIndex}
                      setActiveIdx={setActiveIndex}
                      itemIndex={idx}
                      className={cx({
                        [styles.ListSearchDropdownResultsItemFullListFirstItem]:
                          idx === 0,
                        [styles.ListSearchDropdownResultsItemFirstItem]:
                          !shouldShowScrollbar && idx === 0,
                        [styles.ListSearchDropdownResultsItemLastItem]:
                          !shouldShowScrollbar &&
                          idx === filteredResults.length - 1,
                      })}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
    return (
      <div onKeyDown={handleKeyDown} tabIndex={1} role="listbox" ref={listRef}>
        {items?.map((item, idx) => {
          return (
            <ListSearchDropdownResultsItem
              item={item}
              onItemClick={() => onClick(item, idx)}
              key={`${item.id}-${idx}`}
              isActive={!!activeIndex}
              setActiveIdx={setActiveIndex}
              itemIndex={idx}
              className={cx({
                [styles.ListSearchDropdownResultsItemFullListFirstItem]:
                  idx === 0,
                [styles.ListSearchDropdownResultsItemFirstItem]:
                  !shouldShowScrollbar && idx === 0,
                [styles.ListSearchDropdownResultsItemLastItem]:
                  !shouldShowScrollbar && idx === items.length - 1,
              })}
              resultsRef={(el: HTMLDivElement | null) =>
                (itemsRefs.current[idx] = el)
              }
            />
          );
        })}
      </div>
    );
  };

  const pendingContent = () => (
    <PendingContent loading={isLoading} hideContent>
      {shouldShowNoResultsText && (
        <div className={styles.ListSearchDropdownResultsEmpty}>
          {configuration.noResultsText}
        </div>
      )}
      {renderResults()}
    </PendingContent>
  );

  const rootClassNames = cx(styles.ListSearchDropdownResults, {
    [styles.ListSearchDropdownResultsMobile]: isMobile,
  });

  return (
    <div
      className={cx(rootClassNames, resultsClassname)}
      ref={ref}
      data-testid="listSearchDropdownResults"
    >
      {shouldShowScrollbar && maxHeight ? (
        <PerfectScrollbar
          style={{
            maxHeight: `${maxHeight}px`,
            paddingRight: '8px',
          }}
          options={{ minScrollbarLength: 20 }}
        >
          {pendingContent()}
        </PerfectScrollbar>
      ) : (
        pendingContent()
      )}
    </div>
  );
};

export default ListSearchDropdownResults;
