import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';

import { SingletonHooksContainer } from 'react-singleton-hook';

import { ROUTES } from 'Consts/routes';

import { PageLoading } from 'UI/Layout/Page';

import {
  IntroPage,
  LoginPage,
  BellLoginPage,
  MagicLinkPage,
  GlobalAuthPage,
  ResetPasswordPage,
} from 'Pages/Onboarding';
import Home from 'Pages/Homepage';
import WlanCard from 'Pages/Homepage/Wlan view all';
import {
  Employee as EmployeeZone,
  GuestZone,
  Secure as SecureZone,
} from 'Pages/Zones';
import DevicesViewAll from 'Pages/Zones/Device management view all';
import PopularDevicesViewAll from 'Pages/Zones/Guest zone/Popular devices view all';
import Shield from 'Pages/Shield';
import Network from 'Pages/Network';
import Settings from 'Pages/Settings';
import Device from 'Pages/Device';
import Employee from 'Pages/Employee';
import NotFound from 'Pages/Not found';
import { SETTINGS_LIST } from 'Pages/Settings/definitions';
import { setupStore, AppDispatch } from 'State/store';
import useAuth from 'State/hooks/useAuth';
import * as actions from 'State/actions';
import useLocations from 'State/hooks/useLocations';
import useQuery from 'Utils/hooks/useQueryParams';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'normalize.css';
import './i18n';
import './Styles/index.css';
import AppContainer from './AppContainer';
import PartnerAuthenticationCallbackPage from 'Pages/PartnerAuthenticationCallback';
import AppTimeInsights from 'Pages/AppTimeInsights';
import { useAuth0 } from '@auth0/auth0-react';
import { getPostAuthRoute } from 'Utils/postAuthRedirectUtils';
import { ErrorAlertModal } from 'UI/Elements/Modals/Alerts';
import SsidConfiguration, {
  SSID_CONFIGURATION_LIST,
} from 'Pages/SsidConfiguration';
import Insurance from 'Pages/Insurance';
import NextIframe from 'Pages/Insurance/NextIframe';

export const LoginRoute: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const { isLoading, token } = useAuth();

  if (isLoading) {
    return <PageLoading />;
  }

  if (token) {
    const postAuthRoute = getPostAuthRoute();
    return <Navigate to={postAuthRoute} replace />;
  }

  return children;
};

const ProtectedRoute: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { locations, availableLocationCount } = useLocations();
  const { isLoading, token, onAuthFailure } = useAuth();
  const query = useQuery();
  const routerLocation = useLocation();

  let timeoutId: NodeJS.Timeout | null = null; // moving out of state to prevent re-renders
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    if (query.get('debug') === 'true') {
      dispatch(actions.debug.setDebug(true));
    } else if (query.get('debug') === 'false') {
      dispatch(actions.debug.setDebug(false));
    }
    if (token?.startsWith('Bearer') && !timeoutId) {
      const localTimeoutId = setTimeout(checkAccessToken, 5000, token);
      timeoutId = localTimeoutId;
    }
  }, [dispatch, query, token]);

  useEffect(() => {
    if (locations.errorMessage && token) {
      onAuthFailure(token);
    }
  }, [locations.errorMessage]);

  const clearTimer = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  const checkAccessToken = async (previousToken: string) => {
    try {
      const newtoken = 'Bearer ' + (await getAccessTokenSilently());
      if (previousToken && newtoken !== previousToken) {
        dispatch(actions.auth.saveJustAuthToLocalStorage(newtoken));
      }
      clearTimer();
      const localTimeoutId = setTimeout(checkAccessToken, 60000, newtoken);
      timeoutId = localTimeoutId;
    } catch {
      console.error('Caught error checkAccessToken');
    }
  };

  useEffect(() => {
    if (
      !locations.isLoading &&
      Array.isArray(locations.data) &&
      availableLocationCount === 0
    ) {
      // TODO: perhaps Dispatch LOGOUT with error action - and then Redirect to login with query string
      localStorage.setItem('onboardingAlert', 'noWorkPass');
      dispatch(actions.auth.logout());
    }
  });

  if (isLoading) {
    return <PageLoading />;
  }

  if (!token) {
    const redirectRoute = routerLocation.pathname;
    localStorage.setItem('redirectRoute', redirectRoute);

    return <Navigate to={ROUTES.onboarding.index} replace />;
  }

  return children;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = setupStore();

const uiVersion =
  process.env.REACT_APP_NPM_VERSION +
  ` (` +
  (
    process.env.REACT_APP_SOURCE_VERSION ||
    process.env.REACT_APP_GIT_HASH ||
    ''
  ).substring(0, 7) +
  `)`;

localStorage.setItem('settingsExpanded', 'false'); // always start with settings closed
console.log(`UI Version: ${uiVersion}`);

root.render(
  <Provider store={store}>
    <SingletonHooksContainer />

    <AppContainer>
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTES.onboarding.index}
            element={
              <LoginRoute>
                <IntroPage />
              </LoginRoute>
            }
          />

          <Route
            path={ROUTES.onboarding.magicLink}
            element={
              <LoginRoute>
                <MagicLinkPage />
              </LoginRoute>
            }
          />

          <Route
            path={ROUTES.onboarding.globalAuth}
            element={
              <LoginRoute>
                <GlobalAuthPage />
              </LoginRoute>
            }
          />

          <Route
            path={ROUTES.onboarding.login}
            element={
              <LoginRoute>
                <LoginPage />
              </LoginRoute>
            }
          />

          <Route
            path={ROUTES.onboarding.bellLogin}
            element={
              <LoginRoute>
                <BellLoginPage />
              </LoginRoute>
            }
          />

          <Route
            path={ROUTES.onboarding.resetPassword}
            element={
              <LoginRoute>
                <ResetPasswordPage />
              </LoginRoute>
            }
          />

          <Route
            path={ROUTES.partnerAuthenticationCallback}
            element={
              <LoginRoute>
                <PartnerAuthenticationCallbackPage />
              </LoginRoute>
            }
          />

          <Route
            path={ROUTES.home.index}
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.home.wlan}
            element={
              <ProtectedRoute>
                <WlanCard />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.secure.index}
            element={
              <ProtectedRoute>
                <SecureZone />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.secure.approved}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="secure" view="approved" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.secure.approvedByGroup}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="secure" view="approved" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.secure.unapproved}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="secure" view="unapproved" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.secure.blocked}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="secure" view="blocked" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.secure.quarantined}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="secure" view="quarantined" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.employee.index}
            element={
              <ProtectedRoute>
                <EmployeeZone />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.employee.approved}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="employee" view="approved" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.employee.unapproved}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="employee" view="unapproved" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.employee.blocked}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="employee" view="blocked" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.employee.quarantined}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="employee" view="quarantined" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.guest.index}
            element={
              <ProtectedRoute>
                <GuestZone />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.zones.guest.popularDevices}
            element={
              <ProtectedRoute>
                <PopularDevicesViewAll />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.shield}
            element={
              <ProtectedRoute>
                <Shield />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.network}
            element={
              <ProtectedRoute>
                <Network />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.index}
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.secure}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.secure} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.employee}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.employee} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.employeeLogin}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.employeeLogin} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.guest}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.guest} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.shield}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.shield} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.pods}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.pods} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.support}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.support} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.settings.account}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.account} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.deviceByMac}
            element={
              <ProtectedRoute>
                <Device />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.employeeById}
            element={
              <ProtectedRoute>
                <Employee />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.appTimeInsightsByEmployeeId}
            element={
              <ProtectedRoute>
                <AppTimeInsights />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.appTimeInsightsByNetworkId}
            element={
              <ProtectedRoute>
                <AppTimeInsights />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.employeeByIdAssignedDevices}
            element={
              <ProtectedRoute>
                <DevicesViewAll zone="employee" view="assigned" />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.ssidConfiguration.configuration}
            element={
              <ProtectedRoute>
                <SsidConfiguration
                  active={SSID_CONFIGURATION_LIST.ssidConfiguration}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.ssidConfiguration.pods}
            element={
              <ProtectedRoute>
                <SsidConfiguration active={SSID_CONFIGURATION_LIST.pods} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.ssidConfiguration.support}
            element={
              <ProtectedRoute>
                <SsidConfiguration active={SSID_CONFIGURATION_LIST.support} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.ssidConfiguration.account}
            element={
              <ProtectedRoute>
                <SsidConfiguration active={SSID_CONFIGURATION_LIST.account} />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.ssidConfiguration.secure}
            element={
              <ProtectedRoute>
                <SsidConfiguration active={SSID_CONFIGURATION_LIST.secure} />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ssidConfiguration.employee}
            element={
              <ProtectedRoute>
                <SsidConfiguration active={SSID_CONFIGURATION_LIST.employee} />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ssidConfiguration.guest}
            element={
              <ProtectedRoute>
                <SsidConfiguration active={SSID_CONFIGURATION_LIST.guest} />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.insurance.index}
            element={
              <ProtectedRoute>
                <Insurance />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.insurance.next}
            element={
              <ProtectedRoute>
                <NextIframe />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.settings.businessInfo}
            element={
              <ProtectedRoute>
                <Settings active={SETTINGS_LIST.businessInfo} />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <ErrorAlertModal />
      </BrowserRouter>
    </AppContainer>
    {/* </React.StrictMode> */}
  </Provider>
);

// switch to .register for PWA
serviceWorkerRegistration.unregister();
