import React, { FunctionComponent } from 'react';
import Icon, { IconNames } from 'UI/Elements/Icon';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import styles from './style.module.css';
import { Body1, Heading4Medium } from 'UI/Elements/Typography';

export type FeatureAnnouncementProps = {
  title: string;
  paragraph: string;
  callToActionLabel: string;
  cancelActionLabel: string;
  handleCancelAction: () => void;
  handleCallToAction: () => void;
};

const FeatureAnnouncement: FunctionComponent<FeatureAnnouncementProps> = ({
  title,
  paragraph,
  callToActionLabel,
  cancelActionLabel,
  handleCancelAction,
  handleCallToAction,
}) => {
  return (
    <div className={styles.featureAnnouncement}>
      <div className={styles.featureAnnouncementTop}>
        <Heading4Medium>{title}</Heading4Medium>
        <Body1 className={styles.featureAnnouncementParagraph}>
          {paragraph}
        </Body1>
      </div>
      <Icon name={IconNames.KeycardIllustration} />
      <div className={styles.featureAnnouncementBottom}>
        <Button
          className={styles.featureAnnouncementCta}
          label={callToActionLabel}
          onClick={handleCallToAction}
        />
        <Button
          className={styles.featureAnnouncementCta}
          label={cancelActionLabel}
          onClick={handleCancelAction}
          theme={BUTTON_THEMES.white}
        />
      </div>
    </div>
  );
};

export default FeatureAnnouncement;
