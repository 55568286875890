import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { Body3, Body3Medium } from 'UI/Elements/Typography';
import { IconName, IconShape } from 'UI/Elements/Icon/icons';
import Divider from 'UI/Elements/Divider';
import ListSearchDropdownResults, {
  ListSearchDropdownResultsItem,
} from './ListSearchDropdownResults';
import { getDefaultConfiguration } from '../../../../Pages/Settings/SettingsContent/Components/BusinessInfo/businessInfoConfiguration.helper';
import useListSearchDropdown from './hooks/useListSearchDropdown';
import { ListSearchDropdownContext } from './ListSearchDropdownContext';
import styles from './style.module.css';
import useIsMobile from 'Utils/hooks/useIsMobile';

export type ListSearchDropdownFilter = {
  label: string;
  name: string;
};

export type ListSearchDropdownParams = {
  selectedFilter?: ListSearchDropdownFilter;
};

export type ListSearchDropdownConfiguration = {
  minCharactersToSearch: number;
  placeholderText: string;
  noResultsText: string;
  displayFilters: boolean;
  closeOnSelectItem: boolean;
  icon?: ReactNode;
  clearIcon?: boolean;
  clearQuery?: boolean;
  maxHeight?: number;
  openInPortal?: boolean;
  searchInputSmall?: boolean;
};
export type ListSearchDropdownEvents = {
  onSearch?: (
    query: string,
    params: ListSearchDropdownParams
  ) => Promise<ListSearchDropdownResultsItem[]>;
  onError?: (error: any) => void;
};
export type ListSearchDropdownProps = {
  availableFilters?: ListSearchDropdownFilter[];
  configuration?: Partial<ListSearchDropdownConfiguration>;
  onItemClick?: (item: ListSearchDropdownResultsItem) => void;
  className?: string;
  resultsClassname?: string;
  showSelection?: boolean;
  fullList: ListSearchDropdownResultsItem[];
  openOnClick: boolean;
  smallLabel?: string;
  placeholder?: string;
  error?: string;
  selectedItem: ListSearchDropdownResultsItem | undefined;
  setSelectedItem: (item: ListSearchDropdownResultsItem | undefined) => void;
  iconName?: IconName;
} & ListSearchDropdownEvents &
  JSX.IntrinsicElements['div'];

const ListSearchDropdown: FunctionComponent<ListSearchDropdownProps> = ({
  onSearch,
  onError,
  configuration,
  onItemClick,
  availableFilters = [],
  className,
  resultsClassname,
  showSelection,
  fullList,
  openOnClick,
  smallLabel,
  placeholder,
  error,
  iconName,
  selectedItem,
  setSelectedItem,
}) => {
  const config = getDefaultConfiguration(configuration);

  const events = {
    onSearch,
    onError,
  };
  const {
    isExpanded,
    setIsExpanded,
    onInputFieldClick,
    autoCompleteResults,
    isLoading,
    onInput,
    query,
    setQuery,
  } = useListSearchDropdown(availableFilters, events, config);
  const elRef = useRef(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [focusFirstItem, setFocusFirstItem] = useState(false);
  const [activeDescendantId, setActiveDescendantId] = useState('');
  const isMobile = useIsMobile();

  const onSelectedItemClick = (item: ListSearchDropdownResultsItem) => {
    setQuery(item.label);
    onItemClick?.(item);
    if (configuration?.clearQuery) {
      setQuery('');
    }
    if (showSelection) {
      setSelectedItem(item);
    }
    onInput(item.id);
    setIsExpanded(false);
  };

  const onInputChange = () => {
    setSelectedItem(undefined);
  };

  useEffect(() => {
    if (isMobile && isExpanded && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isMobile, isExpanded]);

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' && isExpanded) {
      if (!event.shiftKey) {
        event.preventDefault();
        setFocusFirstItem(true);
        setTimeout(() => {
          setFocusFirstItem(false);
        }, 0);
      } else {
        event.preventDefault();
        setFocusFirstItem(false);
        searchInputRef.current?.focus();
      }
    }
  };

  return (
    <div
      className={cx(styles.ListSearchDropdown, {
        [styles.ListSearchDropdownDanger]: !!error,
      })}
    >
      <div
        className={cx(styles.ListSearchDropdownWrapper, className)}
        ref={elRef}
        onKeyDown={keyDownHandler}
      >
        <ListSearchDropdownContext.Provider
          value={{
            configuration: config,
            isLoading,
            availableFilters,
            closeSearchResults: () => setIsExpanded(false),
          }}
        >
          {iconName && (
            <Icon
              name={iconName}
              className={styles.ListSearchDropdownIconParent}
            />
          )}
          <div className={styles.ListSearchDropdownWrapperLabeledContent}>
            <Body3Medium>{smallLabel}</Body3Medium>
            <input
              data-testid="listSearchDropdown-input"
              name="search"
              type="text"
              autoComplete="off"
              onClick={onInputFieldClick}
              value={showSelection && selectedItem ? selectedItem.label : query}
              placeholder={placeholder}
              onInput={(e) => onInput(e.currentTarget.value)}
              onChange={onInputChange}
              className={styles.ListSearchDropdownInput}
              role="combobox"
              aria-autocomplete="both"
              aria-expanded={isExpanded}
              aria-owns="auto-complete-list"
              aria-activedescendant={activeDescendantId}
              aria-controls="auto-complete-list"
              id="listSearchDropdown"
              ref={searchInputRef}
            />
          </div>
          <Icon
            className={styles.ListSearchDropdownWrapperLabeledContentRightIcon}
            name={isExpanded ? IconNames.ChevronUp : IconNames.ChevronDown}
            shape={IconShape.square}
            onClick={() => setIsExpanded(isExpanded ? false : true)}
          />
          {isExpanded && (
            <ListSearchDropdownResults
              maxHeight={
                isMobile
                  ? window.innerHeight -
                    (listRef.current?.getBoundingClientRect().y || 0) -
                    30
                  : 200
              }
              items={openOnClick && !query ? fullList : autoCompleteResults}
              onItemClick={onSelectedItemClick}
              isOpen={isExpanded}
              toggle={(state: boolean) => setIsExpanded(state)}
              resultsClassname={resultsClassname}
              setActiveDescendantId={setActiveDescendantId}
              focusFirstItem={focusFirstItem}
              listRef={listRef}
            />
          )}
        </ListSearchDropdownContext.Provider>
      </div>
      <Divider className={styles.ListSearchInputFieldLine} />
      <Body3 className={styles.ListSearchInputFieldBelowInputLine}>
        {error}
      </Body3>
    </div>
  );
};

export default ListSearchDropdown;
