import { useCallback, useState } from 'react';
import {
  ListSearchDropdownConfiguration,
  ListSearchDropdownEvents,
  ListSearchDropdownFilter,
} from '../ListSearchDropdown';
import { ListSearchDropdownResultsItem } from '../ListSearchDropdownResults';
import { useClickOutside } from 'UI/Components/AutoComplete/hooks/useClickOutside';

type TogglerElementTypes = {
  key: string;
  label: string | React.ReactElement;
};

const useListSearchDropdown = (
  availableFilters: ListSearchDropdownFilter[],
  events: ListSearchDropdownEvents,
  config: ListSearchDropdownConfiguration
) => {
  const [selectedFilter, setSelectedFilter] =
    useState<ListSearchDropdownFilter>();
  const [query, setQuery] = useState<string>('');
  const [autoCompleteResults, setAutoCompleteResults] =
    useState<ListSearchDropdownResultsItem[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { ref } = useClickOutside({
    toggler: () => setIsExpanded((prev) => !prev),
    state: isExpanded,
  });

  const getFilterBy = (
    property: keyof ListSearchDropdownFilter,
    value: string
  ): ListSearchDropdownFilter | undefined => {
    return availableFilters.find((filter) => filter[property] === value);
  };

  const getAvailableFilters = useCallback(() => {
    if (availableFilters?.length === 0) {
      return [];
    }
    return availableFilters.map((filter) => {
      return {
        key: filter.name,
        label: filter.label,
      };
    });
  }, [availableFilters]);

  const onSelectFilter = async (selection: TogglerElementTypes) => {
    const previousFilter = selectedFilter;
    const filterToBeSelected = {
      name: selection.key,
      label: selection.label.toString(),
    };
    setSelectedFilter(filterToBeSelected);
    if (selection.key !== previousFilter?.name && query?.length) {
      await getAsyncData(query, filterToBeSelected);
    }
  };

  const getDefaultFilterName = (): string | undefined => {
    if (!availableFilters) {
      return undefined;
    }
    return selectedFilter?.name || availableFilters[0]?.name || '';
  };

  const getAsyncData = async (
    query: string,
    filter?: ListSearchDropdownFilter
  ) => {
    setQuery(query);
    setIsLoading(true);
    setIsExpanded(true);
    const filterToPass =
      !config.displayFilters && availableFilters?.length ? undefined : filter;

    try {
      const results = await events.onSearch?.(query, {
        selectedFilter: filterToPass,
      });
      if (results) {
        setAutoCompleteResults(results);
      }
    } catch (error) {
      events.onError?.(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInput = async (query: string) => {
    if (query && query.length < config.minCharactersToSearch) {
      return;
    }
    const name = getDefaultFilterName();
    const filter = name ? getFilterBy('name', name) : undefined;
    if (availableFilters.length && !filter) {
      return;
    }
    await getAsyncData(query, filter);
  };

  const onInputFieldClick = (event: any) => {
    if (query && query.length) {
      setIsExpanded(true);
      event.target.select();
    }
  };

  return {
    ref,
    isLoading,
    onInputFieldClick,
    isExpanded,
    onInput,
    onSelectFilter,
    getAvailableFilters,
    autoCompleteResults,
    setAutoCompleteResults,
    setIsExpanded,
    query,
    setQuery,
  };
};
export default useListSearchDropdown;
