import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'UI/Elements/Card';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import { BusinessDayType, BusinessHoursState } from './types';
import StandardListItem from 'UI/Components/Lists/List standard';
import Divider from 'UI/Elements/Divider';
import styles from './style.module.css';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import OpenCloseHoursSidepanel from './OpenCloseHoursSidepanel';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import { OpenTriggerEventType } from 'Consts/types';

const businessHoursInitialState: BusinessHoursState = {
  sunday: {
    open: {
      hours: '',
      minutes: '',
      amPm: '',
    },
    close: {
      hours: '',
      minutes: '',
      amPm: '',
    },
  },
  monday: {
    open: {
      hours: '',
      minutes: '',
      amPm: '',
    },
    close: {
      hours: '',
      minutes: '',
      amPm: '',
    },
  },
  tuesday: {
    open: {
      hours: '',
      minutes: '',
      amPm: '',
    },
    close: {
      hours: '',
      minutes: '',
      amPm: '',
    },
  },
  wednesday: {
    open: {
      hours: '',
      minutes: '',
      amPm: '',
    },
    close: {
      hours: '',
      minutes: '',
      amPm: '',
    },
  },
  thursday: {
    open: {
      hours: '',
      minutes: '',
      amPm: '',
    },
    close: {
      hours: '',
      minutes: '',
      amPm: '',
    },
  },
  friday: {
    open: {
      hours: '',
      minutes: '',
      amPm: '',
    },
    close: {
      hours: '',
      minutes: '',
      amPm: '',
    },
  },
  saturday: {
    open: {
      hours: '',
      minutes: '',
      amPm: '',
    },
    close: {
      hours: '',
      minutes: '',
      amPm: '',
    },
  },
};

const BusinessInfoHoursSidepanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setContent } = useSidepanel();
  const dispatchReduxAction = useDispatch<AppDispatch>();

  const handleOpenOpenCloseHoursSidepanel = useCallback(
    (ev: React.MouseEvent, day: BusinessDayType) => {
      dispatchReduxAction(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<OpenCloseHoursSidepanel day={day} />);
    },
    [setContent]
  );

  return (
    <SimpleSidePanel title={t('settings.businessInfo.businessHours')}>
      <SidePanelContentWrapper>
        <Card>
          {Object.keys(businessHoursInitialState).map((day, index) => (
            <div key={`${day}-${index}`}>
              <StandardListItem
                ariaLabel={day}
                L2Props={{
                  label: t(`settings.businessInfo.businessHoursDays.${day}`),
                }}
                RProps={{
                  smallLabel: t('common.edit'),
                  className: styles.editBtn,
                  smallLabelOnClick: (ev: React.MouseEvent) =>
                    handleOpenOpenCloseHoursSidepanel(
                      ev,
                      day as BusinessDayType
                    ),
                }}
              />
              {index !== Object.keys(businessHoursInitialState).length - 1 && (
                <Divider />
              )}
            </div>
          ))}
        </Card>
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};
export default BusinessInfoHoursSidepanel;
