import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'Consts/routes';
import Button, { BUTTON_TYPEFACES } from 'UI/Elements/Button';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';

import { Body3 } from 'UI/Elements/Typography';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';
import { useAuth0 } from '@auth0/auth0-react';
import useNavigateToSettings from 'Utils/hooks/useNavigateToSettings';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import styles from './style.module.css';
import PartnerImg from 'UI/Elements/Partner Logo';
import { Nullable } from 'Consts/types';
import AutoComplete, {
  AutoCompleteParams,
} from '../../Components/AutoComplete/AutoComplete';
import { AutoCompleteResultsItem } from '../../Components/AutoComplete/AutoCompleteResults';
import Menu from 'UI/Components/Menu';
import V2Menu, { MenuItemProps } from 'UI/Molecules/Menu';
import useDevices from 'State/hooks/useDevices';
import useEmployees from 'State/hooks/useEmployees';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import AlertModal from 'UI/Elements/Modals/Alerts';
import Alert from 'UI/Components/Alert';
import { BUTTON_THEMES } from 'UI/Elements/Button';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import useCustomer from 'State/hooks/useCustomer';
import { MenuOpenTriggerEventType } from 'Utils/hooks/useFocusFirstInteractable';

const MAX_NOTIFICATIONS_ALLOWED = 99;
const BELL_NAMESPACE = 'bell';

type MenuElementProps = {
  onClick: React.MouseEventHandler;
};

type BrandingProps = {
  partnerId?: Nullable<string>;
} & MenuElementProps;

type BadgeProps = {
  count: number;
};

type NotificationsProps = {
  noPrimaryDeviceCount?: number;
  unapprovedSecureZoneDevicesCount?: number;
  unapprovedEmployeeZoneDevicesCount?: number;
  businessProfileIncomplete?: boolean;
};

type UserProps = {
  handleSignOut: () => void;
};

const Hamburger: FunctionComponent<MenuElementProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Icon
      name={IconNames.Hamburger}
      shape={IconShape.circle}
      className={styles.hideForNarrowScreens}
      tooltipLabel={t('common.hideNav')}
      onClick={onClick}
    />
  );
};

export const Branding: FunctionComponent<BrandingProps> = ({
  partnerId = undefined,
  onClick,
}) => {
  const environment = getConfigurationFromDomain();

  return environment.id === BELL_NAMESPACE ? (
    <div
      role="button"
      tabIndex={0}
      aria-label="Go to home"
      className={styles.logoLine}
      onClick={onClick}
      data-testid="bell-logo-icon"
    >
      <Icon
        className={styles.logo}
        name={IconNames.BellCompanyBrandingLogo}
        shape={IconShape.rectangle}
      />
    </div>
  ) : (
    <div
      role="button"
      tabIndex={0}
      aria-label="Go to home"
      className={styles.logoLine}
      onClick={onClick}
      data-testid="plume-logo-icon"
    >
      <PartnerImg className={styles.partnerLogo} partnerId={partnerId} />
      <Icon
        className={styles.logo}
        name={IconNames.Logo}
        shape={IconShape.rectangle}
      />
    </div>
  );
};

const Badge: FunctionComponent<BadgeProps> = ({ count }) => {
  const hasMoreThanLimit = !!count && count > MAX_NOTIFICATIONS_ALLOWED;
  const badgeClassNames = cn(styles.badge, {
    [styles.badgeNarrowed]: hasMoreThanLimit,
  });
  const badgeCopy = hasMoreThanLimit ? `${MAX_NOTIFICATIONS_ALLOWED}+` : count;

  return !!count ? (
    <Body3 className={badgeClassNames}>{badgeCopy}</Body3>
  ) : null;
};

const Notifications: FunctionComponent<NotificationsProps> = ({
  noPrimaryDeviceCount = 0,
  unapprovedSecureZoneDevicesCount = 0,
  unapprovedEmployeeZoneDevicesCount = 0,
  businessProfileIncomplete,
}) => {
  const { t } = useTranslation();
  const count =
    (noPrimaryDeviceCount ?? 0) +
    (unapprovedEmployeeZoneDevicesCount ?? 0) +
    (unapprovedSecureZoneDevicesCount ?? 0) +
    (businessProfileIncomplete ? 1 : 0);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownMenuOpenTrigger, setDropdownMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const parentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const namespace = useCspTranslationNamespace();
  const notificationsMenuEmpty: MenuItemProps[] = [
    {
      label: t('common.notificationsTab.noNewNotifications.title'),
      className: cn(styles.notificationsMenuEmpty),
    },
  ];

  const primaryDeviceNotifications = () => {
    if ((noPrimaryDeviceCount ?? 0) > 0) {
      return [
        {
          label:
            noPrimaryDeviceCount === 1
              ? t('common.notificationsTab.noPrimaryDevice.titleOne')
              : t('common.notificationsTab.noPrimaryDevice.title', {
                  noPrimaryDevice: noPrimaryDeviceCount,
                }),
          subtitle: t('common.notificationsTab.noPrimaryDevice.subtitle', {
            ns: namespace,
          }),
          onClick: () => {
            navigate(ROUTES.zones.employee.index);
          },
        },
      ];
    } else {
      return [];
    }
  };

  const unapprovedSecureZoneDeviceNotifications = () => {
    if ((unapprovedSecureZoneDevicesCount ?? 0) > 0) {
      return [
        {
          label:
            unapprovedSecureZoneDevicesCount === 1
              ? t(
                  'common.notificationsTab.unapprovedSecureZoneDevices.titleOne'
                )
              : t('common.notificationsTab.unapprovedSecureZoneDevices.title', {
                  unapprovedSecureZoneDevices: unapprovedSecureZoneDevicesCount,
                }),
          subtitle: t(
            'common.notificationsTab.unapprovedSecureZoneDevices.subtitle'
          ),
          onClick: () => {
            navigate(ROUTES.zones.secure.unapproved);
          },
        },
      ];
    } else {
      return [];
    }
  };

  const unapprovedEmployeeZoneDeviceNotifications = () => {
    if ((unapprovedEmployeeZoneDevicesCount ?? 0) > 0) {
      return [
        {
          label:
            unapprovedEmployeeZoneDevicesCount === 1
              ? t(
                  'common.notificationsTab.unapprovedEmployeeZoneDevices.titleOne'
                )
              : t(
                  'common.notificationsTab.unapprovedEmployeeZoneDevices.title',
                  {
                    unapprovedEmployeeZoneDevices:
                      unapprovedEmployeeZoneDevicesCount,
                  }
                ),
          subtitle: t(
            'common.notificationsTab.unapprovedEmployeeZoneDevices.subtitle'
          ),
          onClick: () => {
            navigate(ROUTES.zones.employee.unapproved);
          },
        },
      ];
    } else {
      return [];
    }
  };

  const incompleteBusinessInfoProfileNotifications = () => {
    return businessProfileIncomplete
      ? [
          {
            label: t(
              'common.notificationsTab.businessInfoProfileNotComplete.title'
            ),
            subtitle: t(
              'common.notificationsTab.businessInfoProfileNotComplete.subtitle'
            ),
            onClick: () => {
              navigate(ROUTES.settings.businessInfo);
            },
          },
        ]
      : [];
  };

  const notificationsMenu: MenuItemProps[] =
    count === 0
      ? [...notificationsMenuEmpty]
      : [
          ...primaryDeviceNotifications(),
          ...(primaryDeviceNotifications().length ? [{ isDivider: true }] : []),
          ...unapprovedSecureZoneDeviceNotifications(),
          ...(unapprovedSecureZoneDeviceNotifications().length
            ? [{ isDivider: true }]
            : []),
          ...unapprovedEmployeeZoneDeviceNotifications(),
          ...(unapprovedEmployeeZoneDeviceNotifications().length
            ? [{ isDivider: true }]
            : []),
          ...incompleteBusinessInfoProfileNotifications(),
        ];

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
    setDropdownMenuOpenTrigger(e.type as MenuOpenTriggerEventType);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div
      className={styles.notifications}
      ref={parentRef}
      data-testid="notifications"
    >
      <Icon
        name={IconNames.Bell}
        shape={IconShape.circle}
        tooltipLabel={t('common.viewNotifications')}
        onClick={handleIconClick}
      />
      <Badge count={count} />
      <V2Menu
        isOpen={dropdownOpen}
        items={notificationsMenu}
        parent={parentRef.current}
        onClose={closeDropdown}
        menuOpenTrigger={dropdownMenuOpenTrigger}
      />
    </div>
  );
};

const User: FunctionComponent<UserProps> = ({ handleSignOut }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpenTrigger, setMenuOpenTrigger] =
    useState<MenuOpenTriggerEventType>();
  const parentRef = useRef<HTMLDivElement>(null);
  const customerData = useCustomer();
  const email = customerData?.data?.email || ' ';

  const userMenu: MenuItemProps[] = [
    {
      label: 'Sign out',
      theme: 'danger',
      iconName: IconNames.SignOut,
      onClick: handleSignOut,
    },
  ];

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setDropdownOpen(!dropdownOpen);
    setMenuOpenTrigger(e.type as MenuOpenTriggerEventType);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div ref={parentRef} data-testid="user">
      <Button
        className={styles.userButton}
        label={email[0].toUpperCase()}
        typeface={BUTTON_TYPEFACES.unstyled}
        onClick={handleIconClick}
      />

      <Menu
        className={styles.userMenu}
        isOpen={dropdownOpen}
        items={userMenu}
        parent={parentRef.current}
        onClose={closeDropdown}
        openTriggerEventType={menuOpenTrigger}
      />
    </div>
  );
};

type TopBarBaseProps = JSX.IntrinsicElements['div'] & {
  showSearchAndNotifications?: boolean;
};

type TopBarUIProps = {
  hamburgerOnClick: React.MouseEventHandler;
  logoOnClick: React.MouseEventHandler;
  settingsOnClick: React.MouseEventHandler;
  noPrimaryDeviceCount: number;
  unapprovedSecureZoneDevicesCount: number;
  unapprovedEmployeeZoneDevicesCount: number;
  withDebugMessage?: boolean;
  partnerId?: Nullable<string>;
  showSearchAndNotifications?: boolean;
  businessProfileIncomplete: boolean;
} & TopBarBaseProps;

enum AutoCompleteResultTypes {
  Employee = 'employee',
  Device = 'device',
}

const mapAutoCompleteTypeToUrlTemplate: Record<
  AutoCompleteResultTypes,
  string
> = {
  [AutoCompleteResultTypes.Employee]: '/employee/:id',
  [AutoCompleteResultTypes.Device]: '/device/:id',
};

export const TopBarUI: FunctionComponent<TopBarUIProps> = ({
  className,
  hamburgerOnClick,
  logoOnClick,
  settingsOnClick,
  noPrimaryDeviceCount,
  unapprovedSecureZoneDevicesCount,
  unapprovedEmployeeZoneDevicesCount,
  withDebugMessage,
  partnerId = undefined,
  showSearchAndNotifications = true,
  businessProfileIncomplete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, logout } = useAuth0();
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const navigate = useNavigate();
  const employees = useSelector(selectors.employees);
  const devices = useSelector(selectors.zones.devices);
  const [openAlert, setOpenAlert] = useState<'signout' | null>(null);

  const handleCloseAlert = useCallback(() => {
    setOpenAlert(null);
  }, []);

  const handleOpenSignoutAlert = useCallback(() => setOpenAlert('signout'), []);

  const onSearch = (query: string, params: AutoCompleteParams) => {
    const employeesData = (employees.data || [])
      .map((d) => {
        return {
          id: d.id,
          label: d.nickname,
          type: AutoCompleteResultTypes.Employee,
        };
      })
      .filter((d) => d.label.toLowerCase().includes(query.toLowerCase()));
    const devicesData = (devices.data || [])
      .map((d) => {
        return {
          id: d.mac,
          label: d.nickname || d.name || d.mac,
          type: AutoCompleteResultTypes.Device,
        };
      })
      .filter((d) => {
        return d.label.toLowerCase().includes(query.toLowerCase());
      });
    const devicesDataIPAddress = (devices.data || [])
      .map((d) => {
        return {
          id: d.mac,
          label: d.ip || '',
          type: AutoCompleteResultTypes.Device,
          searchItem: d.ip,
        };
      })
      .filter((d) => {
        return d.searchItem?.includes(query) && query.length > 2;
      });

    return Promise.resolve([
      ...employeesData,
      ...devicesData,
      ...devicesDataIPAddress,
    ]);
  };

  const onAutoCompleteItemClick = (item: AutoCompleteResultsItem) => {
    const urlTemplate =
      mapAutoCompleteTypeToUrlTemplate[item.type as AutoCompleteResultTypes];
    const url = urlTemplate.replace(':id', item.id);
    navigate(url);
  };

  const handleSignOut = useCallback(() => {
    dispatch(actions.auth.logout());

    if (isAuthenticated) {
      logout({
        logoutParams: {
          returnTo: window.location.origin + '/onboarding/global-auth',
        },
      });
    }

    trackEvent({
      eventName: MixPanelEvents.LOGOUT,
    });
  }, [dispatch]);

  return (
    <div className={cn(styles.topBar, className)}>
      <div className={styles.topBarLeft}>
        <Hamburger onClick={hamburgerOnClick} />
        <Branding partnerId={partnerId} onClick={logoOnClick} />
      </div>

      <div className={styles.topBarRight}>
        {showSearchAndNotifications && (
          <>
            <AutoComplete
              onSearch={onSearch}
              configuration={{ maxHeight: 248 }}
              availableFilters={[
                {
                  name: 'employee',
                  label: 'Employees',
                },
                {
                  name: 'device',
                  label: 'Devices',
                },
              ]}
              onItemClick={onAutoCompleteItemClick}
              autoCompleteInputId="autoComplete-input"
            />
            {withDebugMessage && (
              <div className={styles.debugMessage}>Debug mode</div>
            )}
            <Notifications
              noPrimaryDeviceCount={noPrimaryDeviceCount}
              unapprovedSecureZoneDevicesCount={
                unapprovedSecureZoneDevicesCount
              }
              unapprovedEmployeeZoneDevicesCount={
                unapprovedEmployeeZoneDevicesCount
              }
              businessProfileIncomplete={businessProfileIncomplete}
            />
          </>
        )}
        <User handleSignOut={handleOpenSignoutAlert} />
      </div>

      <AlertModal isOpen={openAlert === 'signout'} onClose={handleCloseAlert}>
        <Alert
          topProps={{
            label: t('settings.signingOut', { ns: namespace }),
            paragraph: t('settings.signingOutText', { ns: namespace }),
            className: styles.modalBody,
          }}
          bottomProps={{
            button1Props: {
              label: t('common.yes'),
              onClick: handleSignOut,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: handleCloseAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>
    </div>
  );
};

const TopBar: FunctionComponent<TopBarBaseProps> = ({
  className,
  showSearchAndNotifications = true,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const navigateToSettings = useNavigateToSettings();
  const activeLocation = useSelector(selectors.locations.activeLocation);

  const debugEnabled = useSelector(selectors.debug);
  const navPanelHidden = useSelector(selectors.ui.page.navPanelHidden);

  const handleHamburgerClick = useCallback(() => {
    dispatch(actions.ui.page.setNavPanelHidden(!navPanelHidden));
    dispatch(actions.ui.page.setSidepanelOpen(false));
  }, [dispatch, navPanelHidden]);

  const handleLogoClick = useCallback(() => {
    dispatch(actions.ui.page.setSidepanelOpen(false));
    navigate(ROUTES.home.index);
  }, [navigate]);

  const { allDevices } = useDevices();
  const allEmployees = useEmployees();
  const networkAccessData = useNetworkAccess();

  const noPrimaryDeviceCount = (allEmployees.data || []).filter(
    (employee) => !employee.primaryDevice
  ).length;

  const unapprovedSecureZoneDevicesCount = useMemo(() => {
    if (!networkAccessData?.data?.default?.purgatory) return 0;
    return (
      allDevices.data?.reduce(
        (count, dev) =>
          dev.networkAccessMode === 'auto' && dev.networkId === 'default'
            ? count + 1
            : count,
        0
      ) || 0
    );
  }, [allDevices.data, networkAccessData.data]);

  const unapprovedEmployeeZoneDevicesCount = useMemo(() => {
    if (!networkAccessData?.data?.employee?.purgatory) return 0;
    return (
      allDevices.data?.reduce(
        (count, dev) =>
          dev.networkAccessMode === 'auto' && dev.networkId === 'employee'
            ? count + 1
            : count,
        0
      ) || 0
    );
  }, [allDevices.data, networkAccessData.data]);

  const businessProfileIncomplete = false;

  const handleSettingsClick = useCallback(() => {
    dispatch(actions.ui.page.setSidepanelOpen(false));
    navigateToSettings(ROUTES.settings.index);
  }, [navigateToSettings]);

  return (
    <TopBarUI
      className={className}
      hamburgerOnClick={handleHamburgerClick}
      logoOnClick={handleLogoClick}
      settingsOnClick={handleSettingsClick}
      noPrimaryDeviceCount={noPrimaryDeviceCount}
      unapprovedSecureZoneDevicesCount={unapprovedSecureZoneDevicesCount}
      unapprovedEmployeeZoneDevicesCount={unapprovedEmployeeZoneDevicesCount}
      withDebugMessage={debugEnabled}
      partnerId={activeLocation.data?.partnerId}
      showSearchAndNotifications={showSearchAndNotifications}
      businessProfileIncomplete={businessProfileIncomplete}
    />
  );
};

export default TopBar;
