export const businessCategories = [
  {
    id: 'accountant',
    label: 'Accountant',
  },
  {
    id: 'accountingFirm',
    label: 'Accounting Firm',
  },
  {
    id: 'acupunctureClinic',
    label: 'Acupuncture Clinic',
  },
  {
    id: 'acupuncturist',
    label: 'Acupuncturist',
  },
  {
    id: 'addictionTreatmentCenter',
    label: 'Addiction Treatment Center',
  },
  {
    id: 'advertisingAgency',
    label: 'Advertising Agency',
  },
  {
    id: 'aerospaceCompany',
    label: 'Aerospace Company',
  },
  {
    id: 'airConditioningContractor',
    label: 'Air Conditioning Contractor',
  },
  {
    id: 'airline',
    label: 'Airline',
  },
  {
    id: 'allergist',
    label: 'Allergist',
  },
  {
    id: 'alternativeMedicinePractitioner',
    label: 'Alternative Medicine Practitioner',
  },
  {
    id: 'anesthesiologist',
    label: 'Anesthesiologist',
  },
  {
    id: 'animalHospital',
    label: 'Animal Hospital',
  },
  {
    id: 'antiqueStore',
    label: 'Antique Store',
  },
  {
    id: 'apartmentBuilding',
    label: 'Apartment Building',
  },
  {
    id: 'apartmentComplex',
    label: 'Apartment Complex',
  },
  {
    id: 'apartmentRentalAgency',
    label: 'Apartment Rental Agency',
  },
  {
    id: 'applianceRepairService',
    label: 'Appliance Repair Service',
  },
  {
    id: 'applianceStore',
    label: 'Appliance Store',
  },
  {
    id: 'appraiser',
    label: 'Appraiser',
  },
  {
    id: 'architect',
    label: 'Architect',
  },
  {
    id: 'architectureFirm',
    label: 'Architecture Firm',
  },
  {
    id: 'artGallery',
    label: 'Art Gallery',
  },
  {
    id: 'artStudio',
    label: 'Art Studio',
  },
  {
    id: 'artist',
    label: 'Artist',
  },
  {
    id: 'artsOrganization',
    label: 'Arts Organization',
  },
  {
    id: 'assistedLivingFacility',
    label: 'Assisted Living Facility',
  },
  {
    id: 'associationOrganization',
    label: 'Association Organization',
  },
  {
    id: 'atm',
    label: 'Atm',
  },
  {
    id: 'attorney',
    label: 'Attorney',
  },
  {
    id: 'auctionHouse',
    label: 'Auction House',
  },
  {
    id: 'audiologist',
    label: 'Audiologist',
  },
  {
    id: 'autoBodyShop',
    label: 'Auto Body Shop',
  },
  {
    id: 'autoGlassShop',
    label: 'Auto Glass Shop',
  },
  {
    id: 'autoInsuranceAgency',
    label: 'Auto Insurance Agency',
  },
  {
    id: 'autoPartsStore',
    label: 'Auto Parts Store',
  },
  {
    id: 'autoRepairShop',
    label: 'Auto Repair Shop',
  },
  {
    id: 'bailBondsService',
    label: 'Bail Bonds Service',
  },
  {
    id: 'bakery',
    label: 'Bakery',
  },
  {
    id: 'bank',
    label: 'Bank',
  },
  {
    id: 'bankruptcyAttorney',
    label: 'Bankruptcy Attorney',
  },
  {
    id: 'baptistChurch',
    label: 'Baptist Church',
  },
  {
    id: 'bar',
    label: 'Bar',
  },
  {
    id: 'barGrill',
    label: 'Bar Grill',
  },
  {
    id: 'barberShop',
    label: 'Barber Shop',
  },
  {
    id: 'beautySalon',
    label: 'Beauty Salon',
  },
  {
    id: 'beautySupplyStore',
    label: 'Beauty Supply Store',
  },
  {
    id: 'bicycleStore',
    label: 'Bicycle Store',
  },
  {
    id: 'biotechnologyCompany',
    label: 'Biotechnology Company',
  },
  {
    id: 'bookStore',
    label: 'Book Store',
  },
  {
    id: 'bookkeepingService',
    label: 'Bookkeeping Service',
  },
  {
    id: 'bookstore',
    label: 'Bookstore',
  },
  {
    id: 'boutique',
    label: 'Boutique',
  },
  {
    id: 'brewery',
    label: 'Brewery',
  },
  {
    id: 'bridalShop',
    label: 'Bridal Shop',
  },
  {
    id: 'bubbleTeaStore',
    label: 'Bubble Tea Store',
  },
  {
    id: 'buildingMaterialsStore',
    label: 'Building Materials Store',
  },
  {
    id: 'buildingMaterialsSupplier',
    label: 'Building Materials Supplier',
  },
  {
    id: 'businessCenter',
    label: 'Business Center',
  },
  {
    id: 'businessDevelopmentService',
    label: 'Business Development Service',
  },
  {
    id: 'businessManagementConsultant',
    label: 'Business Management Consultant',
  },
  {
    id: 'butcherShop',
    label: 'Butcher Shop',
  },
  {
    id: 'cabinetMaker',
    label: 'Cabinet Maker',
  },
  {
    id: 'cableCompany',
    label: 'Cable Company',
  },
  {
    id: 'cafe',
    label: 'Cafe',
  },
  {
    id: 'cancerTreatmentCenter',
    label: 'Cancer Treatment Center',
  },
  {
    id: 'cannabisStore',
    label: 'Cannabis Store',
  },
  {
    id: 'carDealer',
    label: 'Car Dealer',
  },
  {
    id: 'carDetailingService',
    label: 'Car Detailing Service',
  },
  {
    id: 'carRentalAgency',
    label: 'Car Rental Agency',
  },
  {
    id: 'carRepairAndMaintenance',
    label: 'Car Repair And Maintenance',
  },
  {
    id: 'carWash',
    label: 'Car Wash',
  },
  {
    id: 'cardiologist',
    label: 'Cardiologist',
  },
  {
    id: 'carpetCleaningService',
    label: 'Carpet Cleaning Service',
  },
  {
    id: 'caterer',
    label: 'Caterer',
  },
  {
    id: 'catholicChurch',
    label: 'Catholic Church',
  },
  {
    id: 'cellPhoneStore',
    label: 'Cell Phone Store',
  },
  {
    id: 'certifiedPublicAccountant',
    label: 'Certified Public Accountant',
  },
  {
    id: 'charity',
    label: 'Charity',
  },
  {
    id: 'charterSchool',
    label: 'Charter School',
  },
  {
    id: 'childCareAgency',
    label: 'Child Care Agency',
  },
  {
    id: "children'sClothingStore",
    label: "Children's Clothing Store",
  },
  {
    id: 'chiropractor',
    label: 'Chiropractor',
  },
  {
    id: 'christianChurch',
    label: 'Christian Church',
  },
  {
    id: 'church',
    label: 'Church',
  },
  {
    id: 'cityGovernmentOffice',
    label: 'City Government Office',
  },
  {
    id: 'civilEngineer',
    label: 'Civil Engineer',
  },
  {
    id: 'cleaners',
    label: 'Cleaners',
  },
  {
    id: 'cleaningService',
    label: 'Cleaning Service',
  },
  {
    id: 'clothingStore',
    label: 'Clothing Store',
  },
  {
    id: 'coffeeShop',
    label: 'Coffee Shop',
  },
  {
    id: 'college',
    label: 'College',
  },
  {
    id: 'commercialCleaningService',
    label: 'Commercial Cleaning Service',
  },
  {
    id: 'commercialPrinter',
    label: 'Commercial Printer',
  },
  {
    id: 'commercialRealEstateAgency',
    label: 'Commercial Real Estate Agency',
  },
  {
    id: 'communityCenter',
    label: 'Community Center',
  },
  {
    id: 'computerConsultant',
    label: 'Computer Consultant',
  },
  {
    id: 'computerRepairService',
    label: 'Computer Repair Service',
  },
  {
    id: 'computerService',
    label: 'Computer Service',
  },
  {
    id: 'computerSupportAndServices',
    label: 'Computer Support And Services',
  },
  {
    id: 'concreteContractor',
    label: 'Concrete Contractor',
  },
  {
    id: 'conservationDepartment',
    label: 'Conservation Department',
  },
  {
    id: 'constructionCompany',
    label: 'Construction Company',
  },
  {
    id: 'constructionEquipmentSupplier',
    label: 'Construction Equipment Supplier',
  },
  {
    id: 'consultant',
    label: 'Consultant',
  },
  {
    id: 'contractor',
    label: 'Contractor',
  },
  {
    id: 'convenienceStore',
    label: 'Convenience Store',
  },
  {
    id: 'corporateOffice',
    label: 'Corporate Office',
  },
  {
    id: 'cosmeticsStore',
    label: 'Cosmetics Store',
  },
  {
    id: 'counselor',
    label: 'Counselor',
  },
  {
    id: 'countertopStore',
    label: 'Countertop Store',
  },
  {
    id: 'countyGovernmentOffice',
    label: 'County Government Office',
  },
  {
    id: 'courierService',
    label: 'Courier Service',
  },
  {
    id: 'craftStore',
    label: 'Craft Store',
  },
  {
    id: 'creditCounselingService',
    label: 'Credit Counseling Service',
  },
  {
    id: 'creditUnion',
    label: 'Credit Union',
  },
  {
    id: 'criminalJusticeAttorney',
    label: 'Criminal Justice Attorney',
  },
  {
    id: 'customHomeBuilder',
    label: 'Custom Home Builder',
  },
  {
    id: 'danceSchool',
    label: 'Dance School',
  },
  {
    id: 'dayCareCenter',
    label: 'Day Care Center',
  },
  {
    id: 'daySpa',
    label: 'Day Spa',
  },
  {
    id: 'deli',
    label: 'Deli',
  },
  {
    id: 'dentalClinic',
    label: 'Dental Clinic',
  },
  {
    id: 'dentist',
    label: 'Dentist',
  },
  {
    id: 'departmentStore',
    label: 'Department Store',
  },
  {
    id: 'dermatologist',
    label: 'Dermatologist',
  },
  {
    id: 'designAgency',
    label: 'Design Agency',
  },
  {
    id: 'dessertShop',
    label: 'Dessert Shop',
  },
  {
    id: 'dialysisCenter',
    label: 'Dialysis Center',
  },
  {
    id: 'diner',
    label: 'Diner',
  },
  {
    id: 'discountStore',
    label: 'Discount Store',
  },
  {
    id: 'distributionService',
    label: 'Distribution Service',
  },
  {
    id: 'divorceLawyer',
    label: 'Divorce Lawyer',
  },
  {
    id: 'djService',
    label: 'Dj Service',
  },
  {
    id: 'doctor',
    label: 'Doctor',
  },
  {
    id: 'dogDayCareCenter',
    label: 'Dog Day Care Center',
  },
  {
    id: 'dogTrainer',
    label: 'Dog Trainer',
  },
  {
    id: 'dollarStore',
    label: 'Dollar Store',
  },
  {
    id: 'donutShop',
    label: 'Donut Shop',
  },
  {
    id: 'doorSupplier',
    label: 'Door Supplier',
  },
  {
    id: 'drivingSchool',
    label: 'Driving School',
  },
  {
    id: 'drugStore',
    label: 'Drug Store',
  },
  {
    id: 'dryCleaner',
    label: 'Dry Cleaner',
  },
  {
    id: 'e-CommerceService',
    label: 'E-Commerce Service',
  },
  {
    id: 'educationCenter',
    label: 'Education Center',
  },
  {
    id: 'educationalConsultant',
    label: 'Educational Consultant',
  },
  {
    id: 'educationalInstitution',
    label: 'Educational Institution',
  },
  {
    id: 'electricUtilityCompany',
    label: 'Electric Utility Company',
  },
  {
    id: 'electricalSupplyStore',
    label: 'Electrical Supply Store',
  },
  {
    id: 'electrician',
    label: 'Electrician',
  },
  {
    id: 'electronicsStore',
    label: 'Electronics Store',
  },
  {
    id: 'elementarySchool',
    label: 'Elementary School',
  },
  {
    id: 'emergencyCarePhysician',
    label: 'Emergency Care Physician',
  },
  {
    id: 'employmentAgency',
    label: 'Employment Agency',
  },
  {
    id: 'endocrinologist',
    label: 'Endocrinologist',
  },
  {
    id: 'engineer',
    label: 'Engineer',
  },
  {
    id: 'engineeringConsultant',
    label: 'Engineering Consultant',
  },
  {
    id: 'entertainmentAgency',
    label: 'Entertainment Agency',
  },
  {
    id: 'environmentalConsultant',
    label: 'Environmental Consultant',
  },
  {
    id: 'equipmentRentalAgency',
    label: 'Equipment Rental Agency',
  },
  {
    id: 'estatePlanningAttorney',
    label: 'Estate Planning Attorney',
  },
  {
    id: 'eventManagementCompany',
    label: 'Event Management Company',
  },
  {
    id: 'eventPlanner',
    label: 'Event Planner',
  },
  {
    id: 'eventVenue',
    label: 'Event Venue',
  },
  {
    id: 'eyeCareCenter',
    label: 'Eye Care Center',
  },
  {
    id: 'eyelashSalon',
    label: 'Eyelash Salon',
  },
  {
    id: 'facialSpa',
    label: 'Facial Spa',
  },
  {
    id: 'familyCounselor',
    label: 'Family Counselor',
  },
  {
    id: 'familyLawAttorney',
    label: 'Family Law Attorney',
  },
  {
    id: 'familyPracticePhysician',
    label: 'Family Practice Physician',
  },
  {
    id: 'farm',
    label: 'Farm',
  },
  {
    id: 'fashionAccessoriesStore',
    label: 'Fashion Accessories Store',
  },
  {
    id: 'federalCreditUnion',
    label: 'Federal Credit Union',
  },
  {
    id: 'federalGovernmentOffice',
    label: 'Federal Government Office',
  },
  {
    id: 'fenceContractor',
    label: 'Fence Contractor',
  },
  {
    id: 'financialConsultant',
    label: 'Financial Consultant',
  },
  {
    id: 'financialInstitution',
    label: 'Financial Institution',
  },
  {
    id: 'financialPlanner',
    label: 'Financial Planner',
  },
  {
    id: 'fireStation',
    label: 'Fire Station',
  },
  {
    id: 'fireworksStore',
    label: 'Fireworks Store',
  },
  {
    id: 'fitnessCenter',
    label: 'Fitness Center',
  },
  {
    id: 'flooringContractor',
    label: 'Flooring Contractor',
  },
  {
    id: 'flooringStore',
    label: 'Flooring Store',
  },
  {
    id: 'florist',
    label: 'Florist',
  },
  {
    id: 'foodProductsSupplier',
    label: 'Food Products Supplier',
  },
  {
    id: 'foundation',
    label: 'Foundation',
  },
  {
    id: 'fraternalOrganization',
    label: 'Fraternal Organization',
  },
  {
    id: 'freightForwardingService',
    label: 'Freight Forwarding Service',
  },
  {
    id: 'frozenYogurtShop',
    label: 'Frozen Yogurt Shop',
  },
  {
    id: 'funeralHome',
    label: 'Funeral Home',
  },
  {
    id: 'furnitureRentalService',
    label: 'Furniture Rental Service',
  },
  {
    id: 'furnitureStore',
    label: 'Furniture Store',
  },
  {
    id: 'garageDoorSupplier',
    label: 'Garage Door Supplier',
  },
  {
    id: 'garbageCollectionService',
    label: 'Garbage Collection Service',
  },
  {
    id: 'gardenCenter',
    label: 'Garden Center',
  },
  {
    id: 'gasCompany',
    label: 'Gas Company',
  },
  {
    id: 'gasStation',
    label: 'Gas Station',
  },
  {
    id: 'gastroenterologist',
    label: 'Gastroenterologist',
  },
  {
    id: 'generalContractor',
    label: 'General Contractor',
  },
  {
    id: 'generalPractitioner',
    label: 'General Practitioner',
  },
  {
    id: 'giftShop',
    label: 'Gift Shop',
  },
  {
    id: 'governmentOffice',
    label: 'Government Office',
  },
  {
    id: 'graphicDesigner',
    label: 'Graphic Designer',
  },
  {
    id: 'groceryStore',
    label: 'Grocery Store',
  },
  {
    id: 'gunShop',
    label: 'Gun Shop',
  },
  {
    id: 'gym',
    label: 'Gym',
  },
  {
    id: 'hairSalon',
    label: 'Hair Salon',
  },
  {
    id: 'hairdresser',
    label: 'Hairdresser',
  },
  {
    id: 'handyman',
    label: 'Handyman',
  },
  {
    id: 'hardwareStore',
    label: 'Hardware Store',
  },
  {
    id: 'healthAndBeautyShop',
    label: 'Health And Beauty Shop',
  },
  {
    id: 'healthConsultant',
    label: 'Health Consultant',
  },
  {
    id: 'healthInsuranceAgency',
    label: 'Health Insurance Agency',
  },
  {
    id: 'hearingAidStore',
    label: 'Hearing Aid Store',
  },
  {
    id: 'heatingContractor',
    label: 'Heating Contractor',
  },
  {
    id: 'highSchool',
    label: 'High School',
  },
  {
    id: 'holisticMedicinePractitioner',
    label: 'Holistic Medicine Practitioner',
  },
  {
    id: 'homeBuilder',
    label: 'Home Builder',
  },
  {
    id: 'homeGoodsStore',
    label: 'Home Goods Store',
  },
  {
    id: 'homeHealthCareService',
    label: 'Home Health Care Service',
  },
  {
    id: 'homeImprovementStore',
    label: 'Home Improvement Store',
  },
  {
    id: 'homeInspector',
    label: 'Home Inspector',
  },
  {
    id: 'hospice',
    label: 'Hospice',
  },
  {
    id: 'hospital',
    label: 'Hospital',
  },
  {
    id: 'hotel',
    label: 'Hotel',
  },
  {
    id: 'houseCleaningService',
    label: 'House Cleaning Service',
  },
  {
    id: 'humanResourceConsulting',
    label: 'Human Resource Consulting',
  },
  {
    id: 'hvacContractor',
    label: 'Hvac Contractor',
  },
  {
    id: 'iceCreamShop',
    label: 'Ice Cream Shop',
  },
  {
    id: 'immigrationAttorney',
    label: 'Immigration Attorney',
  },
  {
    id: 'industrialEquipmentSupplier',
    label: 'Industrial Equipment Supplier',
  },
  {
    id: 'infectiousDiseasePhysician',
    label: 'Infectious Disease Physician',
  },
  {
    id: 'insulationContractor',
    label: 'Insulation Contractor',
  },
  {
    id: 'insuranceAgency',
    label: 'Insurance Agency',
  },
  {
    id: 'insuranceBroker',
    label: 'Insurance Broker',
  },
  {
    id: 'insuranceCompany',
    label: 'Insurance Company',
  },
  {
    id: 'interiorDesigner',
    label: 'Interior Designer',
  },
  {
    id: 'internetMarketingService',
    label: 'Internet Marketing Service',
  },
  {
    id: 'internetServiceProvider',
    label: 'Internet Service Provider',
  },
  {
    id: 'internist',
    label: 'Internist',
  },
  {
    id: 'investmentCompany',
    label: 'Investment Company',
  },
  {
    id: 'investmentService',
    label: 'Investment Service',
  },
  {
    id: 'janitorialService',
    label: 'Janitorial Service',
  },
  {
    id: 'jeweler',
    label: 'Jeweler',
  },
  {
    id: 'jewelryDesigner',
    label: 'Jewelry Designer',
  },
  {
    id: 'jewelryStore',
    label: 'Jewelry Store',
  },
  {
    id: 'juiceShop',
    label: 'Juice Shop',
  },
  {
    id: 'kitchenRemodeler',
    label: 'Kitchen Remodeler',
  },
  {
    id: 'laborUnion',
    label: 'Labor Union',
  },
  {
    id: 'laboratory',
    label: 'Laboratory',
  },
  {
    id: 'landscapeDesigner',
    label: 'Landscape Designer',
  },
  {
    id: 'landscaper',
    label: 'Landscaper',
  },
  {
    id: 'landscapingSupplyStore',
    label: 'Landscaping Supply Store',
  },
  {
    id: 'laundromat',
    label: 'Laundromat',
  },
  {
    id: 'lawFirm',
    label: 'Law Firm',
  },
  {
    id: 'lawnCareService',
    label: 'Lawn Care Service',
  },
  {
    id: 'lawyer',
    label: 'Lawyer',
  },
  {
    id: 'learningCenter',
    label: 'Learning Center',
  },
  {
    id: 'legalServices',
    label: 'Legal Services',
  },
  {
    id: 'lifeCoach',
    label: 'Life Coach',
  },
  {
    id: 'lightingStore',
    label: 'Lighting Store',
  },
  {
    id: 'limousineService',
    label: 'Limousine Service',
  },
  {
    id: 'lingerieStore',
    label: 'Lingerie Store',
  },
  {
    id: 'liquorStore',
    label: 'Liquor Store',
  },
  {
    id: 'loanAgency',
    label: 'Loan Agency',
  },
  {
    id: 'locksmith',
    label: 'Locksmith',
  },
  {
    id: 'logisticsService',
    label: 'Logistics Service',
  },
  {
    id: 'lutheranChurch',
    label: 'Lutheran Church',
  },
  {
    id: 'machineShop',
    label: 'Machine Shop',
  },
  {
    id: 'mailingService',
    label: 'Mailing Service',
  },
  {
    id: 'manufacturer',
    label: 'Manufacturer',
  },
  {
    id: 'marketingAgency',
    label: 'Marketing Agency',
  },
  {
    id: 'marketingConsultant',
    label: 'Marketing Consultant',
  },
  {
    id: 'marriageOrRelationshipCounselor',
    label: 'Marriage Or Relationship Counselor',
  },
  {
    id: 'martialArtsSchool',
    label: 'Martial Arts School',
  },
  {
    id: 'massageSpa',
    label: 'Massage Spa',
  },
  {
    id: 'massageTherapist',
    label: 'Massage Therapist',
  },
  {
    id: 'mattressStore',
    label: 'Mattress Store',
  },
  {
    id: 'mediaCompany',
    label: 'Media Company',
  },
  {
    id: 'medicalCenter',
    label: 'Medical Center',
  },
  {
    id: 'medicalClinic',
    label: 'Medical Clinic',
  },
  {
    id: 'medicalDiagnosticImagingCenter',
    label: 'Medical Diagnostic Imaging Center',
  },
  {
    id: 'medicalEquipmentSupplier',
    label: 'Medical Equipment Supplier',
  },
  {
    id: 'medicalGroup',
    label: 'Medical Group',
  },
  {
    id: 'medicalLaboratory',
    label: 'Medical Laboratory',
  },
  {
    id: 'medicalOffice',
    label: 'Medical Office',
  },
  {
    id: 'medicalSpa',
    label: 'Medical Spa',
  },
  {
    id: 'medicalSupplyStore',
    label: 'Medical Supply Store',
  },
  {
    id: "men'sClothingStore",
    label: "Men's Clothing Store",
  },
  {
    id: 'mentalHealthClinic',
    label: 'Mental Health Clinic',
  },
  {
    id: 'mentalHealthService',
    label: 'Mental Health Service',
  },
  {
    id: 'metalFabricator',
    label: 'Metal Fabricator',
  },
  {
    id: 'middleSchool',
    label: 'Middle School',
  },
  {
    id: 'militaryRecruitingOffice',
    label: 'Military Recruiting Office',
  },
  {
    id: 'mobileHomePark',
    label: 'Mobile Home Park',
  },
  {
    id: 'mobilePhoneRepairShop',
    label: 'Mobile Phone Repair Shop',
  },
  {
    id: 'moneyTransferService',
    label: 'Money Transfer Service',
  },
  {
    id: 'mortgageBroker',
    label: 'Mortgage Broker',
  },
  {
    id: 'mortgageLender',
    label: 'Mortgage Lender',
  },
  {
    id: 'mover',
    label: 'Mover',
  },
  {
    id: 'movieTheater',
    label: 'Movie Theater',
  },
  {
    id: 'movingAndStorageService',
    label: 'Moving And Storage Service',
  },
  {
    id: 'movingCompany',
    label: 'Moving Company',
  },
  {
    id: 'museum',
    label: 'Museum',
  },
  {
    id: 'musicInstructor',
    label: 'Music Instructor',
  },
  {
    id: 'musicSchool',
    label: 'Music School',
  },
  {
    id: 'nailSalon',
    label: 'Nail Salon',
  },
  {
    id: 'nephrologist',
    label: 'Nephrologist',
  },
  {
    id: 'neurologist',
    label: 'Neurologist',
  },
  {
    id: 'neurosurgeon',
    label: 'Neurosurgeon',
  },
  {
    id: 'newspaperPublisher',
    label: 'Newspaper Publisher',
  },
  {
    id: 'non-DenominationalChurch',
    label: 'Non-Denominational Church',
  },
  {
    id: 'non-ProfitOrganization',
    label: 'Non-Profit Organization',
  },
  {
    id: 'nursePractitioner',
    label: 'Nurse Practitioner',
  },
  {
    id: 'nursingAgency',
    label: 'Nursing Agency',
  },
  {
    id: 'nursingHome',
    label: 'Nursing Home',
  },
  {
    id: 'nutritionist',
    label: 'Nutritionist',
  },
  {
    id: 'obstetrician-Gynecologist',
    label: 'Obstetrician-Gynecologist',
  },
  {
    id: 'occupationalTherapist',
    label: 'Occupational Therapist',
  },
  {
    id: 'officeSpaceRentalAgency',
    label: 'Office Space Rental Agency',
  },
  {
    id: 'officeSupplyStore',
    label: 'Office Supply Store',
  },
  {
    id: 'oilChangeService',
    label: 'Oil Change Service',
  },
  {
    id: 'oilNaturalGasCompany',
    label: 'Oil Natural Gas Company',
  },
  {
    id: 'oncologist',
    label: 'Oncologist',
  },
  {
    id: 'ophthalmologist',
    label: 'Ophthalmologist',
  },
  {
    id: 'optician',
    label: 'Optician',
  },
  {
    id: 'optometrist',
    label: 'Optometrist',
  },
  {
    id: 'oralSurgeon',
    label: 'Oral Surgeon',
  },
  {
    id: 'organization',
    label: 'Organization',
  },
  {
    id: 'orthodontist',
    label: 'Orthodontist',
  },
  {
    id: 'orthopedicClinic',
    label: 'Orthopedic Clinic',
  },
  {
    id: 'orthopedicSurgeon',
    label: 'Orthopedic Surgeon',
  },
  {
    id: 'orthoticsProstheticsService',
    label: 'Orthotics Prosthetics Service',
  },
  {
    id: 'otolaryngologist',
    label: 'Otolaryngologist',
  },
  {
    id: 'painManagementPhysician',
    label: 'Pain Management Physician',
  },
  {
    id: 'paintStore',
    label: 'Paint Store',
  },
  {
    id: 'painter',
    label: 'Painter',
  },
  {
    id: 'painting',
    label: 'Painting',
  },
  {
    id: 'park',
    label: 'Park',
  },
  {
    id: 'parkingGarage',
    label: 'Parking Garage',
  },
  {
    id: 'parkingLot',
    label: 'Parking Lot',
  },
  {
    id: 'partyEquipmentRentalService',
    label: 'Party Equipment Rental Service',
  },
  {
    id: 'pavingContractor',
    label: 'Paving Contractor',
  },
  {
    id: 'pawnShop',
    label: 'Pawn Shop',
  },
  {
    id: 'pediatricDentist',
    label: 'Pediatric Dentist',
  },
  {
    id: 'pediatrician',
    label: 'Pediatrician',
  },
  {
    id: 'performingArtsTheater',
    label: 'Performing Arts Theater',
  },
  {
    id: 'permanentMake-UpClinic',
    label: 'Permanent Make-Up Clinic',
  },
  {
    id: 'personalInjuryAttorney',
    label: 'Personal Injury Attorney',
  },
  {
    id: 'personalTrainer',
    label: 'Personal Trainer',
  },
  {
    id: 'pestControlService',
    label: 'Pest Control Service',
  },
  {
    id: 'petGroomer',
    label: 'Pet Groomer',
  },
  {
    id: 'petSupplyStore',
    label: 'Pet Supply Store',
  },
  {
    id: 'petroleumProductsCompany',
    label: 'Petroleum Products Company',
  },
  {
    id: 'pharmaceuticalCompany',
    label: 'Pharmaceutical Company',
  },
  {
    id: 'pharmacy',
    label: 'Pharmacy',
  },
  {
    id: 'photographer',
    label: 'Photographer',
  },
  {
    id: 'photographyService',
    label: 'Photography Service',
  },
  {
    id: 'photographyStudio',
    label: 'Photography Studio',
  },
  {
    id: 'physicalFitnessProgram',
    label: 'Physical Fitness Program',
  },
  {
    id: 'physicalTherapist',
    label: 'Physical Therapist',
  },
  {
    id: 'physicalTherapyClinic',
    label: 'Physical Therapy Clinic',
  },
  {
    id: 'physicianAssistant',
    label: 'Physician Assistant',
  },
  {
    id: 'pictureFrameShop',
    label: 'Picture Frame Shop',
  },
  {
    id: 'pilatesStudio',
    label: 'Pilates Studio',
  },
  {
    id: 'pizzaDelivery',
    label: 'Pizza Delivery',
  },
  {
    id: 'plasticFabricationCompany',
    label: 'Plastic Fabrication Company',
  },
  {
    id: 'plasticSurgeon',
    label: 'Plastic Surgeon',
  },
  {
    id: 'plumber',
    label: 'Plumber',
  },
  {
    id: 'plumbingSupplyStore',
    label: 'Plumbing Supply Store',
  },
  {
    id: 'podiatrist',
    label: 'Podiatrist',
  },
  {
    id: 'portraitStudio',
    label: 'Portrait Studio',
  },
  {
    id: 'postOffice',
    label: 'Post Office',
  },
  {
    id: 'presbyterianChurch',
    label: 'Presbyterian Church',
  },
  {
    id: 'preschool',
    label: 'Preschool',
  },
  {
    id: 'pressureWashingService',
    label: 'Pressure Washing Service',
  },
  {
    id: 'printShop',
    label: 'Print Shop',
  },
  {
    id: 'privateInvestigator',
    label: 'Private Investigator',
  },
  {
    id: 'privateSchool',
    label: 'Private School',
  },
  {
    id: 'professionalServices',
    label: 'Professional Services',
  },
  {
    id: 'promotionalProductsSupplier',
    label: 'Promotional Products Supplier',
  },
  {
    id: 'propaneSupplier',
    label: 'Propane Supplier',
  },
  {
    id: 'propertyManagementCompany',
    label: 'Property Management Company',
  },
  {
    id: 'psychiatrist',
    label: 'Psychiatrist',
  },
  {
    id: 'psychologist',
    label: 'Psychologist',
  },
  {
    id: 'psychotherapist',
    label: 'Psychotherapist',
  },
  {
    id: 'publicLibrary',
    label: 'Public Library',
  },
  {
    id: 'publicNotary',
    label: 'Public Notary',
  },
  {
    id: 'publicRelationsFirm',
    label: 'Public Relations Firm',
  },
  {
    id: 'publisher',
    label: 'Publisher',
  },
  {
    id: 'pulmonologist',
    label: 'Pulmonologist',
  },
  {
    id: 'radioBroadcaster',
    label: 'Radio Broadcaster',
  },
  {
    id: 'radiologist',
    label: 'Radiologist',
  },
  {
    id: 'readyMixConcreteSupplier',
    label: 'Ready Mix Concrete Supplier',
  },
  {
    id: 'realEstateAgency',
    label: 'Real Estate Agency',
  },
  {
    id: 'realEstateAgent',
    label: 'Real Estate Agent',
  },
  {
    id: 'realEstateAgents',
    label: 'Real Estate Agents',
  },
  {
    id: 'realEstateAppraiser',
    label: 'Real Estate Appraiser',
  },
  {
    id: 'realEstateAttorney',
    label: 'Real Estate Attorney',
  },
  {
    id: 'realEstateConsultant',
    label: 'Real Estate Consultant',
  },
  {
    id: 'realEstateDeveloper',
    label: 'Real Estate Developer',
  },
  {
    id: 'recordingStudio',
    label: 'Recording Studio',
  },
  {
    id: 'recreationCenter',
    label: 'Recreation Center',
  },
  {
    id: 'recruiter',
    label: 'Recruiter',
  },
  {
    id: 'recyclingCenter',
    label: 'Recycling Center',
  },
  {
    id: 'rehabilitationCenter',
    label: 'Rehabilitation Center',
  },
  {
    id: 'religiousOrganization',
    label: 'Religious Organization',
  },
  {
    id: 'remodeler',
    label: 'Remodeler',
  },
  {
    id: 'researchInstitute',
    label: 'Research Institute',
  },
  {
    id: 'restaurant',
    label: 'Restaurant',
  },
  {
    id: 'retirementCommunity',
    label: 'Retirement Community',
  },
  {
    id: 'rheumatologist',
    label: 'Rheumatologist',
  },
  {
    id: 'roofingContractor',
    label: 'Roofing Contractor',
  },
  {
    id: 'safetyEquipmentSupplier',
    label: 'Safety Equipment Supplier',
  },
  {
    id: 'sandwichShop',
    label: 'Sandwich Shop',
  },
  {
    id: 'satelliteCommunicationService',
    label: 'Satellite Communication Service',
  },
  {
    id: 'school',
    label: 'School',
  },
  {
    id: 'screenPrinter',
    label: 'Screen Printer',
  },
  {
    id: 'securityGuardService',
    label: 'Security Guard Service',
  },
  {
    id: 'securityService',
    label: 'Security Service',
  },
  {
    id: 'securitySystemSupplier',
    label: 'Security System Supplier',
  },
  {
    id: 'self-StorageFacility',
    label: 'Self-Storage Facility',
  },
  {
    id: 'shippingAndMailingService',
    label: 'Shipping And Mailing Service',
  },
  {
    id: 'shippingCompany',
    label: 'Shipping Company',
  },
  {
    id: 'shoeStore',
    label: 'Shoe Store',
  },
  {
    id: 'shoppingMall',
    label: 'Shopping Mall',
  },
  {
    id: 'shreddingService',
    label: 'Shredding Service',
  },
  {
    id: 'signShop',
    label: 'Sign Shop',
  },
  {
    id: 'skinCareClinic',
    label: 'Skin Care Clinic',
  },
  {
    id: 'socialServicesOrganization',
    label: 'Social Services Organization',
  },
  {
    id: 'socialWorker',
    label: 'Social Worker',
  },
  {
    id: 'softwareCompany',
    label: 'Software Company',
  },
  {
    id: 'solarEnergyCompany',
    label: 'Solar Energy Company',
  },
  {
    id: 'spa',
    label: 'Spa',
  },
  {
    id: 'speechPathologist',
    label: 'Speech Pathologist',
  },
  {
    id: 'sportingGoodsStore',
    label: 'Sporting Goods Store',
  },
  {
    id: 'sportswearStore',
    label: 'Sportswear Store',
  },
  {
    id: 'stateGovernmentOffice',
    label: 'State Government Office',
  },
  {
    id: 'steakHouse',
    label: 'Steak House',
  },
  {
    id: 'storageFacility',
    label: 'Storage Facility',
  },
  {
    id: 'sunglassesStore',
    label: 'Sunglasses Store',
  },
  {
    id: 'supermarket',
    label: 'Supermarket',
  },
  {
    id: 'surgeon',
    label: 'Surgeon',
  },
  {
    id: 'surgicalCenter',
    label: 'Surgical Center',
  },
  {
    id: 'swimmingPoolContractor',
    label: 'Swimming Pool Contractor',
  },
  {
    id: 'tanningSalon',
    label: 'Tanning Salon',
  },
  {
    id: 'tattooShop',
    label: 'Tattoo Shop',
  },
  {
    id: 'taxPreparation',
    label: 'Tax Preparation',
  },
  {
    id: 'taxPreparationService',
    label: 'Tax Preparation Service',
  },
  {
    id: 'telecommunicationsServiceProvider',
    label: 'Telecommunications Service Provider',
  },
  {
    id: 'tempAgency',
    label: 'Temp Agency',
  },
  {
    id: 'thriftStore',
    label: 'Thrift Store',
  },
  {
    id: 'tireShop',
    label: 'Tire Shop',
  },
  {
    id: 'titleCompany',
    label: 'Title Company',
  },
  {
    id: 'tobaccoShop',
    label: 'Tobacco Shop',
  },
  {
    id: 'tourOperator',
    label: 'Tour Operator',
  },
  {
    id: 'towingService',
    label: 'Towing Service',
  },
  {
    id: 'toyStore',
    label: 'Toy Store',
  },
  {
    id: 'trainingCentre',
    label: 'Training Centre',
  },
  {
    id: 'transportationService',
    label: 'Transportation Service',
  },
  {
    id: 'travelAgency',
    label: 'Travel Agency',
  },
  {
    id: 'treeService',
    label: 'Tree Service',
  },
  {
    id: 'truckAccessoriesStore',
    label: 'Truck Accessories Store',
  },
  {
    id: 'truckDealer',
    label: 'Truck Dealer',
  },
  {
    id: 'truckRentalAgency',
    label: 'Truck Rental Agency',
  },
  {
    id: 'truckRepairShop',
    label: 'Truck Repair Shop',
  },
  {
    id: 'truckStop',
    label: 'Truck Stop',
  },
  {
    id: 'truckingCompany',
    label: 'Trucking Company',
  },
  {
    id: 'tutoringService',
    label: 'Tutoring Service',
  },
  {
    id: 'uniformStore',
    label: 'Uniform Store',
  },
  {
    id: 'unitedMethodistChurch',
    label: 'United Methodist Church',
  },
  {
    id: 'university',
    label: 'University',
  },
  {
    id: 'universityDepartment',
    label: 'University Department',
  },
  {
    id: 'urgentCareCenter',
    label: 'Urgent Care Center',
  },
  {
    id: 'urologist',
    label: 'Urologist',
  },
  {
    id: 'usedCarDealer',
    label: 'Used Car Dealer',
  },
  {
    id: 'vaporizerStore',
    label: 'Vaporizer Store',
  },
  {
    id: 'vendingMachineSupplier',
    label: 'Vending Machine Supplier',
  },
  {
    id: 'veteransOrganization',
    label: 'Veterans Organization',
  },
  {
    id: 'veterinarian',
    label: 'Veterinarian',
  },
  {
    id: 'videoProductionService',
    label: 'Video Production Service',
  },
  {
    id: 'vitaminSupplementsStore',
    label: 'Vitamin Supplements Store',
  },
  {
    id: 'warehouse',
    label: 'Warehouse',
  },
  {
    id: 'wasteManagementService',
    label: 'Waste Management Service',
  },
  {
    id: 'waterDamageRestorationService',
    label: 'Water Damage Restoration Service',
  },
  {
    id: 'waxingHairRemovalService',
    label: 'Waxing Hair Removal Service',
  },
  {
    id: 'websiteDesigner',
    label: 'Website Designer',
  },
  {
    id: 'weddingPhotographer',
    label: 'Wedding Photographer',
  },
  {
    id: 'weightLossService',
    label: 'Weight Loss Service',
  },
  {
    id: 'wellnessCenter',
    label: 'Wellness Center',
  },
  {
    id: 'wholesaler',
    label: 'Wholesaler',
  },
  {
    id: 'windowCleaningService',
    label: 'Window Cleaning Service',
  },
  {
    id: 'windowInstallationService',
    label: 'Window Installation Service',
  },
  {
    id: 'windowTintingService',
    label: 'Window Tinting Service',
  },
  {
    id: 'windowTreatmentStore',
    label: 'Window Treatment Store',
  },
  {
    id: "women'sClothingStore",
    label: "Women's Clothing Store",
  },
  {
    id: "women'sHealthClinic",
    label: "Women's Health Clinic",
  },
  {
    id: 'yogaStudio',
    label: 'Yoga Studio',
  },
  {
    id: 'youthOrganization',
    label: 'Youth Organization',
  },
];

const makeCamelCase = (word: string) => {
  const splitWord = word.split('');
  const firstLetter = splitWord[0].toLowerCase();
  const restOfWord = word.substring(1).replaceAll(' ', '');
  return firstLetter.concat(restOfWord);
};

export const convert = (categories: any[]) => {
  return categories.map((cat) => {
    const camelCase = makeCamelCase(cat);
    return {
      id: camelCase,
      label: cat,
    };
  });
};

export const categories = [
  'Accountant',
  'Accounting Firm',
  'Acupuncture Clinic',
  'Acupuncturist',
  'Addiction Treatment Center',
  'Advertising Agency',
  'Aerospace Company',
  'Air Conditioning Contractor',
  'Airline',
  'Allergist',
  'Alternative Medicine Practitioner',
  'Anesthesiologist',
  'Animal Hospital',
  'Antique Store',
  'Apartment Building',
  'Apartment Complex',
  'Apartment Rental Agency',
  'Appliance Repair Service',
  'Appliance Store',
  'Appraiser',
  'Architect',
  'Architecture Firm',
  'Art Gallery',
  'Art Studio',
  'Artist',
  'Arts Organization',
  'Assisted Living Facility',
  'Association Organization',
  'Atm',
  'Attorney',
  'Auction House',
  'Audiologist',
  'Auto Body Shop',
  'Auto Glass Shop',
  'Auto Insurance Agency',
  'Auto Parts Store',
  'Auto Repair Shop',
  'Bail Bonds Service',
  'Bakery',
  'Bank',
  'Bankruptcy Attorney',
  'Baptist Church',
  'Bar',
  'Bar Grill',
  'Barber Shop',
  'Beauty Salon',
  'Beauty Supply Store',
  'Bicycle Store',
  'Biotechnology Company',
  'Book Store',
  'Bookkeeping Service',
  'Bookstore',
  'Boutique',
  'Brewery',
  'Bridal Shop',
  'Bubble Tea Store',
  'Building Materials Store',
  'Building Materials Supplier',
  'Business Center',
  'Business Development Service',
  'Business Management Consultant',
  'Butcher Shop',
  'Cabinet Maker',
  'Cable Company',
  'Cafe',
  'Cancer Treatment Center',
  'Cannabis Store',
  'Car Dealer',
  'Car Detailing Service',
  'Car Rental Agency',
  'Car Repair And Maintenance',
  'Car Wash',
  'Cardiologist',
  'Carpet Cleaning Service',
  'Caterer',
  'Catholic Church',
  'Cell Phone Store',
  'Certified Public Accountant',
  'Charity',
  'Charter School',
  'Child Care Agency',
  "Children's Clothing Store",
  'Chiropractor',
  'Christian Church',
  'Church',
  'City Government Office',
  'Civil Engineer',
  'Cleaners',
  'Cleaning Service',
  'Clothing Store',
  'Coffee Shop',
  'College',
  'Commercial Cleaning Service',
  'Commercial Printer',
  'Commercial Real Estate Agency',
  'Community Center',
  'Computer Consultant',
  'Computer Repair Service',
  'Computer Service',
  'Computer Support And Services',
  'Concrete Contractor',
  'Conservation Department',
  'Construction Company',
  'Construction Equipment Supplier',
  'Consultant',
  'Contractor',
  'Convenience Store',
  'Corporate Office',
  'Cosmetics Store',
  'Counselor',
  'Countertop Store',
  'County Government Office',
  'Courier Service',
  'Craft Store',
  'Credit Counseling Service',
  'Credit Union',
  'Criminal Justice Attorney',
  'Custom Home Builder',
  'Dance School',
  'Day Care Center',
  'Day Spa',
  'Deli',
  'Dental Clinic',
  'Dentist',
  'Department Store',
  'Dermatologist',
  'Design Agency',
  'Dessert Shop',
  'Dialysis Center',
  'Diner',
  'Discount Store',
  'Distribution Service',
  'Divorce Lawyer',
  'Dj Service',
  'Doctor',
  'Dog Day Care Center',
  'Dog Trainer',
  'Dollar Store',
  'Donut Shop',
  'Door Supplier',
  'Driving School',
  'Drug Store',
  'Dry Cleaner',
  'E-Commerce Service',
  'Education Center',
  'Educational Consultant',
  'Educational Institution',
  'Electric Utility Company',
  'Electrical Supply Store',
  'Electrician',
  'Electronics Store',
  'Elementary School',
  'Emergency Care Physician',
  'Employment Agency',
  'Endocrinologist',
  'Engineer',
  'Engineering Consultant',
  'Entertainment Agency',
  'Environmental Consultant',
  'Equipment Rental Agency',
  'Estate Planning Attorney',
  'Event Management Company',
  'Event Planner',
  'Event Venue',
  'Eye Care Center',
  'Eyelash Salon',
  'Facial Spa',
  'Family Counselor',
  'Family Law Attorney',
  'Family Practice Physician',
  'Farm',
  'Fashion Accessories Store',
  'Federal Credit Union',
  'Federal Government Office',
  'Fence Contractor',
  'Financial Consultant',
  'Financial Institution',
  'Financial Planner',
  'Fire Station',
  'Fireworks Store',
  'Fitness Center',
  'Flooring Contractor',
  'Flooring Store',
  'Florist',
  'Food Products Supplier',
  'Foundation',
  'Fraternal Organization',
  'Freight Forwarding Service',
  'Frozen Yogurt Shop',
  'Funeral Home',
  'Furniture Rental Service',
  'Furniture Store',
  'Garage Door Supplier',
  'Garbage Collection Service',
  'Garden Center',
  'Gas Company',
  'Gas Station',
  'Gastroenterologist',
  'General Contractor',
  'General Practitioner',
  'Gift Shop',
  'Government Office',
  'Graphic Designer',
  'Grocery Store',
  'Gun Shop',
  'Gym',
  'Hair Salon',
  'Hairdresser',
  'Handyman',
  'Hardware Store',
  'Health And Beauty Shop',
  'Health Consultant',
  'Health Insurance Agency',
  'Hearing Aid Store',
  'Heating Contractor',
  'High School',
  'Holistic Medicine Practitioner',
  'Home Builder',
  'Home Goods Store',
  'Home Health Care Service',
  'Home Improvement Store',
  'Home Inspector',
  'Hospice',
  'Hospital',
  'Hotel',
  'House Cleaning Service',
  'Human Resource Consulting',
  'Hvac Contractor',
  'Ice Cream Shop',
  'Immigration Attorney',
  'Industrial Equipment Supplier',
  'Infectious Disease Physician',
  'Insulation Contractor',
  'Insurance Agency',
  'Insurance Broker',
  'Insurance Company',
  'Interior Designer',
  'Internet Marketing Service',
  'Internet Service Provider',
  'Internist',
  'Investment Company',
  'Investment Service',
  'Janitorial Service',
  'Jeweler',
  'Jewelry Designer',
  'Jewelry Store',
  'Juice Shop',
  'Kitchen Remodeler',
  'Labor Union',
  'Laboratory',
  'Landscape Designer',
  'Landscaper',
  'Landscaping Supply Store',
  'Laundromat',
  'Law Firm',
  'Lawn Care Service',
  'Lawyer',
  'Learning Center',
  'Legal Services',
  'Life Coach',
  'Lighting Store',
  'Limousine Service',
  'Lingerie Store',
  'Liquor Store',
  'Loan Agency',
  'Locksmith',
  'Logistics Service',
  'Lutheran Church',
  'Machine Shop',
  'Mailing Service',
  'Manufacturer',
  'Marketing Agency',
  'Marketing Consultant',
  'Marriage Or Relationship Counselor',
  'Martial Arts School',
  'Massage Spa',
  'Massage Therapist',
  'Mattress Store',
  'Media Company',
  'Medical Center',
  'Medical Clinic',
  'Medical Diagnostic Imaging Center',
  'Medical Equipment Supplier',
  'Medical Group',
  'Medical Laboratory',
  'Medical Office',
  'Medical Spa',
  'Medical Supply Store',
  "Men's Clothing Store",
  'Mental Health Clinic',
  'Mental Health Service',
  'Metal Fabricator',
  'Middle School',
  'Military Recruiting Office',
  'Mobile Home Park',
  'Mobile Phone Repair Shop',
  'Money Transfer Service',
  'Mortgage Broker',
  'Mortgage Lender',
  'Mover',
  'Movie Theater',
  'Moving And Storage Service',
  'Moving Company',
  'Museum',
  'Music Instructor',
  'Music School',
  'Nail Salon',
  'Nephrologist',
  'Neurologist',
  'Neurosurgeon',
  'Newspaper Publisher',
  'Non-Denominational Church',
  'Non-Profit Organization',
  'Nurse Practitioner',
  'Nursing Agency',
  'Nursing Home',
  'Nutritionist',
  'Obstetrician-Gynecologist',
  'Occupational Therapist',
  'Office Space Rental Agency',
  'Office Supply Store',
  'Oil Change Service',
  'Oil Natural Gas Company',
  'Oncologist',
  'Ophthalmologist',
  'Optician',
  'Optometrist',
  'Oral Surgeon',
  'Organization',
  'Orthodontist',
  'Orthopedic Clinic',
  'Orthopedic Surgeon',
  'Orthotics Prosthetics Service',
  'Otolaryngologist',
  'Pain Management Physician',
  'Paint Store',
  'Painter',
  'Painting',
  'Park',
  'Parking Garage',
  'Parking Lot',
  'Party Equipment Rental Service',
  'Paving Contractor',
  'Pawn Shop',
  'Pediatric Dentist',
  'Pediatrician',
  'Performing Arts Theater',
  'Permanent Make-Up Clinic',
  'Personal Injury Attorney',
  'Personal Trainer',
  'Pest Control Service',
  'Pet Groomer',
  'Pet Supply Store',
  'Petroleum Products Company',
  'Pharmaceutical Company',
  'Pharmacy',
  'Photographer',
  'Photography Service',
  'Photography Studio',
  'Physical Fitness Program',
  'Physical Therapist',
  'Physical Therapy Clinic',
  'Physician Assistant',
  'Picture Frame Shop',
  'Pilates Studio',
  'Pizza Delivery',
  'Plastic Fabrication Company',
  'Plastic Surgeon',
  'Plumber',
  'Plumbing Supply Store',
  'Podiatrist',
  'Portrait Studio',
  'Post Office',
  'Presbyterian Church',
  'Preschool',
  'Pressure Washing Service',
  'Print Shop',
  'Private Investigator',
  'Private School',
  'Professional Services',
  'Promotional Products Supplier',
  'Propane Supplier',
  'Property Management Company',
  'Psychiatrist',
  'Psychologist',
  'Psychotherapist',
  'Public Library',
  'Public Notary',
  'Public Relations Firm',
  'Publisher',
  'Pulmonologist',
  'Radio Broadcaster',
  'Radiologist',
  'Ready Mix Concrete Supplier',
  'Real Estate Agency',
  'Real Estate Agent',
  'Real Estate Agents',
  'Real Estate Appraiser',
  'Real Estate Attorney',
  'Real Estate Consultant',
  'Real Estate Developer',
  'Recording Studio',
  'Recreation Center',
  'Recruiter',
  'Recycling Center',
  'Rehabilitation Center',
  'Religious Organization',
  'Remodeler',
  'Research Institute',
  'Restaurant',
  'Retirement Community',
  'Rheumatologist',
  'Roofing Contractor',
  'Safety Equipment Supplier',
  'Sandwich Shop',
  'Satellite Communication Service',
  'School',
  'Screen Printer',
  'Security Guard Service',
  'Security Service',
  'Security System Supplier',
  'Self-Storage Facility',
  'Shipping And Mailing Service',
  'Shipping Company',
  'Shoe Store',
  'Shopping Mall',
  'Shredding Service',
  'Sign Shop',
  'Skin Care Clinic',
  'Social Services Organization',
  'Social Worker',
  'Software Company',
  'Solar Energy Company',
  'Spa',
  'Speech Pathologist',
  'Sporting Goods Store',
  'Sportswear Store',
  'State Government Office',
  'Steak House',
  'Storage Facility',
  'Sunglasses Store',
  'Supermarket',
  'Surgeon',
  'Surgical Center',
  'Swimming Pool Contractor',
  'Tanning Salon',
  'Tattoo Shop',
  'Tax Preparation',
  'Tax Preparation Service',
  'Telecommunications Service Provider',
  'Temp Agency',
  'Thrift Store',
  'Tire Shop',
  'Title Company',
  'Tobacco Shop',
  'Tour Operator',
  'Towing Service',
  'Toy Store',
  'Training Centre',
  'Transportation Service',
  'Travel Agency',
  'Tree Service',
  'Truck Accessories Store',
  'Truck Dealer',
  'Truck Rental Agency',
  'Truck Repair Shop',
  'Truck Stop',
  'Trucking Company',
  'Tutoring Service',
  'Uniform Store',
  'United Methodist Church',
  'University',
  'University Department',
  'Urgent Care Center',
  'Urologist',
  'Used Car Dealer',
  'Vaporizer Store',
  'Vending Machine Supplier',
  'Veterans Organization',
  'Veterinarian',
  'Video Production Service',
  'Vitamin Supplements Store',
  'Warehouse',
  'Waste Management Service',
  'Water Damage Restoration Service',
  'Waxing Hair Removal Service',
  'Website Designer',
  'Wedding Photographer',
  'Weight Loss Service',
  'Wellness Center',
  'Wholesaler',
  'Window Cleaning Service',
  'Window Installation Service',
  'Window Tinting Service',
  'Window Treatment Store',
  "Women's Clothing Store",
  "Women's Health Clinic",
  'Yoga Studio',
  'Youth Organization',
];
