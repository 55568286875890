export type BusinessInfoState = {
  businessName: string;
  businessCategory: string;
  businessType: string;
  businessSellOnline: string;
  businessAddress: string;
  businessEmail: string;
  businessPhoneNumber: string;
};

export type BusinessInfoAction = {
  type: 'UPDATE_FIELD';
  field: string;
  value: string;
};

export enum INPUT_FIELDS {
  businessCategory = 'businessCategory',
  businessType = 'businessType',
  businessSellOnline = 'businessSellOnline',
}

export type AmPmType = 'am' | 'pm' | '';
export type BusinessHoursOptions = 'open' | 'close';
export enum BusinessHoursAmPm {
  AM = 'am',
  PM = 'pm',
}

export type BusinessDayType =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export type BusinessHoursState = {
  sunday: {
    open: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
    close: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
  };
  monday: {
    open: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
    close: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
  };
  tuesday: {
    open: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
    close: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
  };
  wednesday: {
    open: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
    close: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
  };
  thursday: {
    open: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
    close: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
  };
  friday: {
    open: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
    close: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
  };
  saturday: {
    open: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
    close: {
      hours: string;
      minutes: string;
      amPm: AmPmType;
    };
  };
};
