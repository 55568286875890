import { t } from 'i18next';
import { ListSearchDropdownConfiguration } from '../../../../../UI/Components/Lists/List search dropdown/ListSearchDropdown';
import React from 'react';

export const getDefaultConfiguration = (
  config?: Partial<ListSearchDropdownConfiguration>
): ListSearchDropdownConfiguration => {
  return {
    noResultsText: t('search.noResults'),
    placeholderText: '',
    closeOnSelectItem: true,
    minCharactersToSearch: 0,
    displayFilters: true,
    ...config,
  };
};

export const getBusinessTypeList = (
  handleDropdownItemSelect: (event: React.MouseEvent<Element>) => void
) => {
  return [
    {
      id: 'franchise',
      label: t('settings.businessInfo.businessCategoryTypes.franchise'),
      onClick: (event: React.MouseEvent<Element>) =>
        handleDropdownItemSelect(event),
    },
    {
      id: 'singleLocation',
      label: t('settings.businessInfo.businessCategoryTypes.singleLocation'),
      onClick: (event: React.MouseEvent<Element>) =>
        handleDropdownItemSelect(event),
    },
  ];
};

export const getSellOnlineOptionList = (
  handleDropdownItemSelect: (event: React.MouseEvent<Element>) => void
) => {
  return [
    {
      id: 'yes',
      label: t('common.yes'),
      onClick: (event: React.MouseEvent<Element>) =>
        handleDropdownItemSelect(event),
    },
    {
      id: 'no',
      label: t('common.no'),
      onClick: (event: React.MouseEvent<Element>) =>
        handleDropdownItemSelect(event),
    },
  ];
};

export const mapLabelToField = (label: string) => {
  switch (label) {
    case t('settings.businessInfo.businessName'):
      return 'businessName';
    case t('settings.businessInfo.businessCategory'):
      return 'businessCategory';
    case t('settings.businessInfo.businessType'):
      return 'businessType';
    case t('settings.businessInfo.businessSellOnline'):
      return 'businessSellOnline';
    case t('settings.businessInfo.businessAddress'):
      return 'businessAddress';
    case t('settings.businessInfo.businessEmail'):
      return 'businessEmail';
    case t('settings.businessInfo.businessPhoneNumber'):
      return 'businessPhoneNumber';
    default:
      return '';
  }
};

const BusinessCategoryOptions = [
  t('settings.businessInfo.businessCategoryTypes.franchise'),
  t('settings.businessInfo.businessCategoryTypes.singleLocation'),
];

const BusinessSellOnlineOptions = [t('common.yes'), t('common.no')];

export const mapDropdownValueToField = (value: string) => {
  if (BusinessCategoryOptions.includes(value)) {
    return 'businessType';
  } else if (BusinessSellOnlineOptions.includes(value)) {
    return 'businessSellOnline';
  } else {
    return 'businessCategory';
  }
};
