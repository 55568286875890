import cn from 'classnames';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { IconNames, Props as IconProps } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { Body1, Body1Medium, Heading3Medium } from 'UI/Elements/Typography';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import useWindowDimensions from 'Utils/hooks/useWindowSize';
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import { OPEN_TRIGGER_EVENTS } from 'Consts/types';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';

type SidePanelContentWrapperProps = JSX.IntrinsicElements['div'];

type SidePanelHeaderProps = {
  leftIconProps?: IconProps;
  leftLabel?: string;
  centerLabel?: string;
  rightLabelProps?: RightLabelProps;
  rightIconProps?: IconProps;
  heading3M?: boolean;
  className?: string;
};

type RightLabelProps = {
  label: string;
  className: string;
  onClick: (ev: React.MouseEvent) => void;
};

export type SimpleSidePanelProps = {
  className?: string;
  children?: React.ReactNode;
  title: string;
  rightIconProps?: IconProps;
  heading3M?: boolean;
  headerClassName?: string;
  rightLabelProps?: RightLabelProps;
  hideCollapseIcon?: boolean;
  onClose?: () => void;
};

export const SidePanelContentWrapper: FunctionComponent<
  PropsWithChildren<SidePanelContentWrapperProps>
> = ({ children, className }) => (
  <div className={cn(styles.sidePanelContentWrapper, className)}>
    {children}
  </div>
);

export const SidePanelHeader: FunctionComponent<SidePanelHeaderProps> = ({
  leftIconProps,
  leftLabel,
  centerLabel,
  rightLabelProps,
  rightIconProps,
  heading3M,
  className,
}) => (
  <div className={cn(styles.sidePanelHeader, className)}>
    <div className={styles.leftHeader}>
      {leftIconProps && <Icon {...leftIconProps} />}
      {leftLabel && <Body1>{leftLabel}</Body1>}
    </div>

    {heading3M ? (
      <Heading3Medium className={styles.centerHeader}>
        {centerLabel}
      </Heading3Medium>
    ) : (
      <Body1Medium className={styles.centerHeader}>{centerLabel}</Body1Medium>
    )}

    <div className={styles.rightHeader}>
      {rightLabelProps && (
        <Body1
          className={rightLabelProps.className}
          onClick={rightLabelProps.onClick}
        >
          {rightLabelProps.label}
        </Body1>
      )}
      {rightIconProps && <Icon {...rightIconProps} />}
    </div>
  </div>
);

export const SimpleSidePanel: FunctionComponent<SimpleSidePanelProps> = ({
  className,
  title,
  children,
  rightIconProps,
  heading3M,
  headerClassName,
  rightLabelProps,
  hideCollapseIcon,
  onClose,
}) => {
  const { t } = useTranslation();
  const { closeSidepanel } = useSidepanel();
  const { width } = useWindowDimensions();
  const sidePanelOpenTrigger = useSelector(
    selectors.ui.page.sidepanelOpenTriggerType
  );
  const dispatch = useDispatch<AppDispatch>();

  const panelContainerRef = React.useRef<HTMLDivElement>(null);

  const handleCloseSidepanel = () => {
    if (onClose) {
      onClose();
    } else {
      closeSidepanel();
    }
  };

  let leftIconProps = hideCollapseIcon
    ? undefined
    : {
        name: IconNames.ArrowRightStop,
        tooltipLabel: t('common.collapse'),
        onClick: handleCloseSidepanel,
        shape: IconShape.square,
      };

  if (width < 473) {
    leftIconProps = {
      name: IconNames.ChevronLeft,
      tooltipLabel: t('common.back'),
      onClick: handleCloseSidepanel,
      shape: IconShape.square,
    };
  }

  useEffect(() => {
    if (sidePanelOpenTrigger === OPEN_TRIGGER_EVENTS.KEYDOWN) {
      const timer = setTimeout(() => {
        if (panelContainerRef.current) {
          const firstFocusableElement =
            panelContainerRef.current.querySelector('[tabindex]');
          if (firstFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
          }
        }
        dispatch(actions.ui.page.setSidepanelOpenTriggerType(null));
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [sidePanelOpenTrigger]);

  return (
    <div
      ref={panelContainerRef}
      className={cn(styles.sidePanel, className)}
      role="complementary"
      aria-label={title}
    >
      <SidePanelHeader
        leftIconProps={leftIconProps}
        centerLabel={title}
        rightIconProps={rightIconProps}
        heading3M={heading3M}
        className={headerClassName}
        rightLabelProps={rightLabelProps}
      />
      {children}
    </div>
  );
};
